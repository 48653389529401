import { BackendResponse, IBook, IMarketData } from '../types/dataFeed'

import { parseBook } from './'

/**
 * @param {string[]} outcomeIds
 */
export const getMarketData = async (outcomeIds: string[]): Promise<IMarketData[]> => {
  try {
    const response = await fetch(`${process.env.GATSBY_API_URL}/market-prices`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ outcomeIds }),
    })
    const book: BackendResponse<IBook> = await response.json()
    if (book.error) {
      throw new Error(book.error)
    }

    const marketData = parseBook(book.data)
    return marketData
  } catch (error) {
    console.log(error)
    return []
  }
}

/**
 * @param {string} phone
 * @param {string} token
 */
export const sendText = async (phone: string, token: string): Promise<BackendResponse<string>> => {
  try {
    const response = await fetch(`${process.env.GATSBY_API_URL}/text-to-download`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone,
        token,
      }),
    })

    const data = await response.json()

    return data
  } catch (error) {
    return {
      data: '',
      error: error as string,
    }
  }
}

/**
 * @param {object} formData
 * @param {string} token
 */
export const submitForm = async (formData: object, token: string): Promise<BackendResponse<string>> => {
  try {
    const response = await fetch(`${process.env.GATSBY_API_URL}/sign-up`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        formData,
        token,
      }),
    })
    const data = await response.json()

    return data
  } catch (error) {
    return {
      data: '',
      error: error as string,
    }
  }
}

/**
 * @param {string} siteKey
 * @param {object} grecaptcha
 */
export const getRecaptchaToken = async (siteKey: any, grecaptcha: any) => {
  try {
    const token = await grecaptcha.execute(siteKey).then((token: any) => {
      return token
    })
    return token
  } catch (error) {
    return error
  }
}
