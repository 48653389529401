import styled from 'styled-components'

export const StyledTitle = styled.span`
  font-family: ${({ theme }) => theme.fontName};
  font-size: ${({ theme }) => theme.fontSizes.h6};
  font-weight: 400;
  line-height: 1.5;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: ${({ theme }) => theme.fontSizes.h6Medium};
  }
`
