import { Builder } from '@builder.io/react'
import Footer from './footer'
import { AssetID } from '../../types/builderio-ids'

Builder.registerComponent(Footer, {
  name: 'Footer',
  inputs: [
    {
      name: 'headingTag',
      type: 'string',
      defaultValue: 'h3',
      regex: {
        pattern: 'h[1-6]',
        message: 'Heading tag must be an h1, h2, h3, h4, h5, or h6',
      },
    },
    { name: 'showForm', type: 'boolean', defaultValue: true },
    {
      name: 'heroArtwork',
      friendlyName: 'Cellphone Artwork',
      type: 'file',
      allowedFileTypes: ['png', 'jpg', 'jpeg', 'svg'],
      required: true,
    },
    {
      name: 'cardIcons',
      type: 'list',
      required: false,
      defaultValue: [],
      subFields: [
        {
          name: 'cardIcon',
          type: 'reference',
          required: true,
          model: 'asset' satisfies AssetID,
          options: {
            enrich: true,
          },
        },
      ],
    },
  ],
  noWrap: true,
})
