import PropTypes from 'prop-types'
import React from 'react'
import { StyledTitle } from './titleFive.styles'

interface TitleFiveProps {
  tag: string
  text: string
  chevron?: boolean
}

const TitleFive = ({ tag, text, chevron }: TitleFiveProps) => {
  return (
    <StyledTitle as={tag}>
      {text}
      {chevron && (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 2l7 7-7 7" stroke="#00875B" strokeWidth="3" />
        </svg>
      )}
    </StyledTitle>
  )
}

export default TitleFive
