import styled from 'styled-components'

interface CalculatorStyledProps {
  activeOption?: boolean
  highlight?: boolean
  highlighted?: boolean
  roundedTop?: boolean
  roundedBottom?: boolean
  underlined?: boolean
  alignLogic?: boolean
}

export const Component = styled.div`
  width: 60rem;
  margin-bottom: 5rem;
  background: ${({ theme }) => theme.primaryWhite};
  box-shadow:
    0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  border: 1px solid ${({ theme }) => theme.secondaryWhite};
  border-radius: 1.5rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: 100%;
  }
`

export const GridContainer = styled.aside`
  padding: 4rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 2rem;
  }
`

export const Footer = styled.div`
  text-align: center;
  border: 1px solid ${({ theme }) => theme.secondaryWhite};
  background: rgba(221, 226, 229, 0.22);
  border-radius: 0 0 1.5rem 1.5rem;
  padding: 2.5rem 0;
`

export const Lede = styled.p`
  color: ${({ theme }) => theme.primaryBlack};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 1.8rem;
  font-weight: 800;
`

export const Title = styled.p`
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.primaryBlack};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 2rem;
  font-weight: 800;

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 1.3rem;
  }
`

export const Text = styled.p<CalculatorStyledProps>`
  color: ${(props) => (props.highlight ? '#232C33' : '#6E7581')};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 1.3rem;
  font-weight: 800;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: 6rem;
    font-size: 1rem;
    font-weight: 600;
  }
`

export const RadioLabel = styled.label`
  display: flex;
  align-items: center;

  svg {
    margin-bottom: -0.3rem;
  }
`

export const RadioLabelText = styled.span`
  margin-right: 1rem;
`

export const Radio = styled.input`
  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  &:checked + label,
  &:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 21px;
    display: inline-block;
    color: ${({ theme }) => theme.primaryBlack};
    font-family: ${({ theme }) => theme.fontName};
    font-size: 1.3rem;
    font-weight: 800;
    text-transform: uppercase;
  }

  &:checked + label:before,
  &:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #dde2e5;
    border-radius: 100%;
    background: #fff;
  }

  &:checked + label:after,
  &:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #0a6dff;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  &:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  &:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`

export const Icon = styled.div<CalculatorStyledProps>`
  display: inline;
  svg {
    fill: ${(props) => (props.activeOption ? '#00875B' : '#868F96')};
  }
`

export const Label = styled.p<CalculatorStyledProps>`
  color: ${(props) => (props.activeOption ? '#6E7581' : '#C9CDCE')};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 1.3rem;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
`

export const StyledNumber = styled.p<CalculatorStyledProps>`
  color: ${(props) => (props.activeOption ? '#00875B' : '#868F96')};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 2rem;
  font-weight: 800;

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 1.3rem;
  }
`

export const NumberTwo = styled.p<CalculatorStyledProps>`
  color: ${(props) => (props.activeOption ? '#232C33' : '#868F96')};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 2rem;
  font-weight: 800;

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 1.3rem;
  }
`

export const Grid = styled.div`
  display: flex;
`

export const ColumnOne = styled.div<CalculatorStyledProps>`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid ${(props) => (props.underlined ? '#D3D5D6' : 'transparent')};
    width: 15%;
  }
`

export const ColumnTwo = styled.div<CalculatorStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${(props) => (props.alignLogic ? 'column' : 'row')};
  border-bottom: 1px solid ${(props) => (props.underlined ? '#D3D5D6' : 'transparent')};
  width: 25%;
  padding: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 2rem 1rem;
  }
`

export const ColumnThree = styled.div<CalculatorStyledProps>`
  display: flex;
  position: relative;
  justify-content: space-around;
  align-items: flex-end;
  flex-direction: row;
  padding: 2rem 1rem 1.5rem;
  width: 45%;
  border-top-left-radius: ${(props) => (props.roundedTop ? '10px' : '0')};
  border-top-right-radius: ${(props) => (props.roundedTop ? '10px' : '0')};
  border-bottom-left-radius: ${(props) => (props.roundedBottom ? '10px' : '0')};
  border-bottom-right-radius: ${(props) => (props.roundedBottom ? '10px' : '0')};
  background: ${(props) => (props.highlighted ? '#EFF1ED' : '#FFF')};
  border-bottom: 1px solid ${(props) => (props.underlined ? '#D3D5D6' : 'transparent')};

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: 50%;
  }
`

export const Stack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
`

export const ColumnFour = styled.div<CalculatorStyledProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid ${(props) => (props.underlined ? '#D3D5D6' : 'transparent')};
  padding: 4rem 1rem 1.5rem;
  width: 25%;

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 2rem 1rem 1.5rem;
  }
`

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Input = styled.input`
  display: block;
  width: 100%;
  margin-left: 0.9rem;
  padding: 0.5rem;
  border-radius: 0.8rem;
  font-size: 1.8rem;
  font-family: ${({ theme }) => theme.fontName};
  font-weight: 700;
  line-height: 1.3;
  border: 1px solid ${({ theme }) => theme.primaryGray};
  color: #868f96;
  background: ${({ theme }) => theme.primaryWhite};
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:hover,
  &:focus {
    border-color: #888;
  }
  &::placeholder {
    color: ${({ theme }) => theme.primaryGray};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 1rem 1.5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 1rem;
  }
`

export const MobileLabel = styled.p`
  font-size: 1.3rem;
  font-family: ${({ theme }) => theme.fontName};
  font-weight: 700;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }
`

export const Logo = styled.img`
  border: 1px solid ${({ theme }) => theme.secondaryWhite};
  border-radius: 50%;
  position: absolute;
  height: 45px;
  top: -2.25rem;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    height: 3rem;
    top: -1.5rem;
  }
`
