import { formatUTCSecondsToDate } from '../helpers/formatDates'
import { Card, DataFeedCardType, DataFeedStructure, IMarketData, IPriceLevel } from '../types/dataFeed'
import { LookupObject } from '../types/parsing'
import { Contest, DisplayState, League, Market, Outcome } from '../types/refData'

const supportedLeagueSymbols = ['NFL', 'NBA', 'WNBA', 'MLB', 'NCAAF', 'NCAAB', 'NHL']

export const getOutcomeIdsForMarketData = (
  contests: LookupObject<Contest>,
  leagues: LookupObject<League>,
  markets: LookupObject<Market>,
  outcomes: Outcome[],
) =>
  outcomes.reduce<string[]>((result, outcome) => {
    if (outcome.displayState !== DisplayState.ACTIVE) return result

    const market = markets[outcome.marketId]
    if (!market) return result

    const contest = contests[market.contestId]
    if (!contest || contest.homeTeamId === '') return result

    const league = leagues[contest.leagueId]
    if (!league) return result

    if (supportedLeagueSymbols.includes(league.symbol)) {
      result.push(outcome.outcomeId)
    }
    return result
  }, [])

const getMaxOrMinPrice = (levels: IPriceLevel[], maxOrMin: 'max' | 'min' = 'max') =>
  levels.reduce<number>((result, level) => {
    if (maxOrMin === 'max') return Math.max(result, level.price)
    else return Math.min(result, level.price)
  }, levels[0].price)

const isCollegiate = (leagueSymbol: string) => ['NCAAB', 'NCAAFB'].includes(leagueSymbol)
const getTeams = (contestName: string) => contestName.split(' @ ')

const sortLeague = (a: string, b: string) => supportedLeagueSymbols.indexOf(a) - supportedLeagueSymbols.indexOf(b)

interface ParseDataFeedArgs {
  leagueLookup: LookupObject<League>
  contestLookup: LookupObject<Contest>
  marketLookup: LookupObject<Market>
  outcomeLookup: LookupObject<Outcome>
  marketData: IMarketData[]
}
export const parseDataFeed = ({
  leagueLookup,
  contestLookup,
  marketLookup,
  outcomeLookup,
  marketData,
}: ParseDataFeedArgs) => {
  const parsedMarketCards = marketData.reduce<Card[]>((result, { outcomeId, askLevels, bidLevels }) => {
    if (askLevels.length === 0 || bidLevels.length === 0) return result

    const outcome = outcomeLookup[outcomeId]
    if (!outcome) return result

    const market = marketLookup[outcome.marketId]
    if (!market) return result

    const contest = contestLookup[market.contestId]
    if (!contest) return result

    const league = leagueLookup[contest.leagueId]
    if (!league) return result

    const contestName = contest.longName,
      leagueSymbol = league.symbol,
      marketTypeSymbol = market.symbol.split(':')[0],
      marketType = market.longName.toUpperCase(),
      isTotalOrSpread = ['SPRD', 'TOT'].includes(marketTypeSymbol),
      homeTeam = isCollegiate(leagueSymbol) ? getTeams(contest.longName)[1] : getTeams(contest.shortName)[1],
      awayTeam = isCollegiate(leagueSymbol) ? getTeams(contest.longName)[0] : getTeams(contest.shortName)[0],
      contestDate = formatUTCSecondsToDate(`${contest.expectedStartTime}`),
      startTime = +contest.expectedStartTime

    const homeSellPrice = getMaxOrMinPrice(bidLevels, 'max'),
      homeBuyPrice = getMaxOrMinPrice(askLevels, 'min'),
      awayBuyPrice = 100 - homeSellPrice,
      awaySellPrice = 100 - homeBuyPrice

    const baseAttributes = {
      league: leagueSymbol,
      startTime,
      marketType,
      contestName,
      contestDate,
      marketTypeSymbol,
    }

    const existingContest = result.find(
      (card: Card) => card.type === DataFeedCardType.CONTEST && card.contestName === contestName,
    )

    if (!existingContest) {
      result.push({
        ...baseAttributes,
        type: DataFeedCardType.CONTEST,
        homeTeam,
        awayTeam,
        sellPrice: '',
        buyPrice: '',
      })
    }

    result.push({
      ...baseAttributes,
      type: DataFeedCardType.MARKET,
      homeTeam: isTotalOrSpread ? outcome.name : homeTeam,
      awayTeam: isTotalOrSpread ? outcome.followerName : awayTeam,
      sellPrice: `$${homeSellPrice}`,
      buyPrice: `$${homeBuyPrice}`,
    })
    result.push({
      ...baseAttributes,
      type: DataFeedCardType.MARKET,
      homeTeam: isTotalOrSpread ? outcome.followerName : awayTeam,
      awayTeam: isTotalOrSpread ? outcome.name : homeTeam,
      sellPrice: `$${awaySellPrice}`,
      buyPrice: `$${awayBuyPrice}`,
    })

    return result
  }, [])

  parsedMarketCards.sort(
    (a, b) =>
      sortLeague(a.league, b.league) ||
      a.startTime - b.startTime ||
      a.contestName.localeCompare(b.contestName) ||
      a.type.localeCompare(b.type),
  )

  return parsedMarketCards.reduce<DataFeedStructure>(
    (result, card) => {
      const existing = result[card.league].find(
        (c) =>
          c.type === card.type &&
          c.contestName === card.contestName &&
          c.marketType === card.marketType &&
          c.homeTeam === card.homeTeam,
      )
      if (!existing) result[card.league].push(card)
      return result
    },
    {
      NFL: [],
      NBA: [],
      WNBA: [],
      MLB: [],
      NCAAF: [],
      NCAAB: [],
      NHL: [],
    },
  )
}
