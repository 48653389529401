import React from 'react'
import { StyledLink } from './navLink.styles'

interface NavLinkProps {
  target: string
  text: string
}

const NavLink = ({ target, text }: NavLinkProps) => {
  return <StyledLink to={target}>{text}</StyledLink>
}

export default NavLink
