import styled from 'styled-components'

interface TextFormStyledProps {
  $focused?: boolean
}

export const Container = styled.aside`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: block;
  }
`

export const Grid = styled.div`
  display: flex;
  align-items: flex-end;
  padding-left: 2rem;
`

export const ColumnOne = styled.div`
  width: 70%;
  margin-left: -2rem;
`

export const ColumnTwo = styled.div`
  width: 30%;
  padding-left: 2rem;
`

export const Label = styled.label`
  display: block;
  margin-bottom: 1.6rem;
  color: ${({ theme }) => theme.secondaryBlack};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 2rem;
  font-weight: 800;
  line-height: 1.1;
`

export const Input = styled.input`
  display: block;
  width: 100%;
  margin: 0;
  padding: 1.6rem 2rem;
  border-radius: 0.8rem;
  font-size: 1.8rem;
  font-family: ${({ theme }) => theme.fontName};
  font-weight: 700;
  line-height: 1.3;
  border: 1px solid ${({ theme }) => theme.primaryGray};
  appearance: none;

  &::placeholder {
    color: ${({ theme }) => theme.secondaryBlack};
  }
`

export const Button = styled.button`
  transition: 0.5s ease background;
  width: 100%;
  display: inline-block;
  padding: 1.6rem 2rem;
  color: ${({ theme }) => theme.primaryWhite};
  background: ${({ theme }) => theme.primaryGreen};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 1.6rem;
  font-weight: 700;
  border-radius: 0.5rem;
  text-decoration: none;
  box-shadow:
    0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.secondaryGreen};
  }
`

export const Drawer = styled.div<TextFormStyledProps>`
  padding-bottom: 4.8rem;
  transition: 0.5s ease all;
  height: ${(props) => (props.$focused ? '100%' : '0')};
  overflow: ${(props) => (props.$focused ? 'auto' : 'hidden')};
  opacity: ${(props) => (props.$focused ? '1' : '0')};

  p {
    padding-top: 3.6rem;
    color: ${({ theme }) => theme.secondaryBlack};
    font-family: ${({ theme }) => theme.fontName};
    font-size: 1.3rem;
  }

  a {
    transition: 0.5s color ease;
    color: ${({ theme }) => theme.secondaryBlack};
    font-weight: 700;

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.secondaryGreen};
    }
  }
`

export const ErrorState = styled.div`
  width: 100%;
  height: 2rem;
  padding-top: 2.5rem;
`

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.tertiaryBlack};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 1.6rem;
`

export const ErrorIcon = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 1.5rem;
  border-radius: 100%;
  background: ${({ theme }) => theme.primaryRed};
`
