import { Link } from './badgeApple.styles'
import React from 'react'

interface BadgeAppleProps {
  target: string
  id: string
  inverse: boolean
}

const BadgeApple = ({ target, id, inverse }: BadgeAppleProps) => {
  return (
    <Link href={target} id={id} target="_blank" rel="noreferrer nofollow" $inverse={inverse}>
      <svg width="177" height="59" viewBox="0 0 177 59" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M162.094 0.000191749H14.0329C13.4932 0.000191749 12.96 0.000191749 12.4217 0.00314174C11.9711 0.00609173 11.5241 0.0146615 11.0692 0.0218742C10.081 0.0335293 9.095 0.120664 8.11994 0.282521C7.14625 0.447901 6.20306 0.75969 5.32227 1.20734C4.44256 1.65878 3.63874 2.24538 2.93998 2.94586C2.23755 3.64437 1.65202 4.45175 1.2059 5.33696C0.758554 6.22041 0.448363 7.1671 0.28604 8.14435C0.122174 9.12036 0.0339957 10.1076 0.0222974 11.0973C0.00863933 11.5495 0.00719699 12.0033 0 12.4556V46.549C0.00719699 47.007 0.00863933 47.4506 0.0222974 47.9088C0.0339993 48.8984 0.122177 49.8856 0.28604 50.8616C0.447915 51.8394 0.758124 52.7866 1.2059 53.6704C1.65181 54.5527 2.23743 55.3569 2.93998 56.0515C3.63609 56.7551 4.44043 57.3421 5.32227 57.7901C6.20305 58.2389 7.14614 58.5526 8.11994 58.7205C9.09517 58.8811 10.081 58.9682 11.0692 58.9813C11.5241 58.9913 11.9711 58.9971 12.4217 58.9971C12.96 59 13.4933 59 14.0329 59H162.094C162.623 59 163.16 59 163.689 58.9971C164.138 58.9971 164.598 58.9913 165.046 58.9813C166.032 58.9689 167.016 58.8817 167.99 58.7205C168.967 58.5514 169.913 58.2378 170.798 57.7901C171.679 57.3418 172.483 56.7548 173.178 56.0515C173.879 55.3541 174.466 54.5506 174.917 53.6704C175.362 52.786 175.669 51.8388 175.828 50.8616C175.993 49.8855 176.084 48.8985 176.102 47.9088C176.107 47.4506 176.107 47.007 176.107 46.549C176.119 46.0131 176.119 45.4802 176.119 44.9356V14.0659C176.119 13.5258 176.119 12.9899 176.107 12.4556C176.107 12.0033 176.107 11.5495 176.102 11.0972C176.084 10.1075 175.993 9.12044 175.828 8.14429C175.669 7.16761 175.361 6.22099 174.917 5.3369C174.009 3.56259 172.568 2.11832 170.798 1.20721C169.913 0.760651 168.966 0.448945 167.99 0.282388C167.017 0.119818 166.033 0.0326531 165.046 0.0216677C164.598 0.0144697 164.138 0.00582623 163.689 0.00294999C163.16 0 162.623 0.000191749 162.094 0.000191749Z"
          fill="#A6A6A6"
        />
        <path
          d="M12.4289 57.7092C11.9805 57.7092 11.5429 57.7035 11.098 57.6934C10.1764 57.6814 9.25688 57.601 8.34707 57.4529C7.49873 57.3064 6.67691 57.0341 5.90872 56.6447C5.14758 56.2586 4.45336 55.7523 3.85265 55.1452C3.24324 54.5453 2.73589 53.8495 2.35068 53.0854C1.96124 52.3163 1.69172 51.492 1.55151 50.641C1.4001 49.7267 1.31818 48.8022 1.30646 47.8754C1.29713 47.5644 1.28491 46.5286 1.28491 46.5286V12.4554C1.28491 12.4554 1.29792 11.4356 1.30653 11.1359C1.31776 10.2107 1.3992 9.28756 1.55016 8.37465C1.69062 7.5214 1.96035 6.69466 2.35001 5.92306C2.7338 5.15948 3.23834 4.46316 3.84407 3.86109C4.44912 3.25331 5.14556 2.74441 5.908 2.35296C6.67442 1.96486 7.49469 1.6944 8.34132 1.55065C9.25411 1.40103 10.1768 1.32014 11.1017 1.30866L12.4297 1.29065H163.682L165.026 1.30938C165.942 1.3203 166.857 1.40046 167.761 1.5492C168.616 1.69476 169.445 1.96711 170.22 2.35728C171.748 3.14617 172.991 4.39428 173.775 5.92666C174.158 6.69293 174.424 7.51293 174.562 8.35881C174.715 9.27921 174.801 10.2096 174.818 11.1424C174.822 11.5602 174.822 12.0088 174.822 12.4554C174.834 13.0085 174.834 13.535 174.834 14.0658V44.9355C174.834 45.4713 174.834 45.9942 174.822 46.5214C174.822 47.001 174.822 47.4404 174.817 47.8927C174.799 48.8089 174.715 49.7227 174.565 50.6266C174.428 51.4837 174.16 52.3144 173.77 53.0898C173.382 53.8456 172.878 54.5354 172.276 55.1337C171.674 55.7441 170.979 56.2533 170.216 56.6419C169.443 57.0343 168.615 57.3076 167.761 57.4529C166.851 57.6018 165.932 57.6822 165.01 57.6934C164.579 57.7035 164.128 57.7092 163.689 57.7092L162.094 57.7122L12.4289 57.7092Z"
          fill="black"
        />
        <path
          d="M36.4543 29.9434C36.4701 28.7123 36.7964 27.5052 37.4028 26.4344C38.0092 25.3636 38.876 24.464 39.9226 23.8191C39.2577 22.8675 38.3806 22.0844 37.3609 21.5319C36.3413 20.9795 35.2071 20.6729 34.0485 20.6366C31.577 20.3766 29.181 22.1187 27.9216 22.1187C26.6378 22.1187 24.6988 20.6624 22.6107 20.7054C21.2601 20.7492 19.9438 21.1428 18.7901 21.8479C17.6364 22.553 16.6847 23.5456 16.0276 24.729C13.1812 29.6679 15.3043 36.9265 18.031 40.9187C19.3951 42.8736 20.9895 45.0572 23.0757 44.9798C25.1171 44.8949 25.8795 43.6752 28.3437 43.6752C30.7851 43.6752 31.5004 44.9798 33.6289 44.9306C35.8195 44.8949 37.1996 42.967 38.5159 40.9936C39.4961 39.6007 40.2504 38.0612 40.7507 36.4322C39.4781 35.8927 38.392 34.9897 37.628 33.8359C36.8639 32.682 36.4557 31.3282 36.4543 29.9434Z"
          fill="white"
        />
        <path
          d="M32.4338 18.011C33.6282 16.574 34.2167 14.727 34.0742 12.8623C32.2494 13.0544 30.5639 13.9284 29.3533 15.3102C28.7615 15.9853 28.3082 16.7706 28.0193 17.6213C27.7305 18.4721 27.6118 19.3715 27.67 20.2683C28.5828 20.2777 29.4857 20.0794 30.3109 19.6884C31.136 19.2974 31.8619 18.7239 32.4338 18.011Z"
          fill="white"
        />
        <path
          d="M62.2595 40.0308H55.293L53.62 44.9815H50.6693L57.2678 26.665H60.3335L66.9321 44.9815H63.931L62.2595 40.0308ZM56.0145 37.7463H61.5366L58.8144 29.7116H58.7382L56.0145 37.7463Z"
          fill="white"
        />
        <path
          d="M81.1827 38.3052C81.1827 42.4551 78.9664 45.1213 75.6218 45.1213C74.7746 45.1657 73.932 44.9701 73.1905 44.5569C72.4491 44.1437 71.8387 43.5296 71.4293 42.7849H71.366V49.3994H68.6309V31.6274H71.2784V33.8486H71.3287C71.7569 33.1074 72.3776 32.4965 73.1247 32.0808C73.8718 31.665 74.7174 31.4601 75.5715 31.4877C78.9534 31.4877 81.1827 34.1669 81.1827 38.3052ZM78.3714 38.3052C78.3714 35.6016 76.9772 33.8241 74.85 33.8241C72.7602 33.8241 71.3545 35.639 71.3545 38.3052C71.3545 40.996 72.7602 42.7979 74.85 42.7979C76.9772 42.7979 78.3714 41.0334 78.3714 38.3052Z"
          fill="white"
        />
        <path
          d="M95.8487 38.3052C95.8487 42.4551 93.6324 45.1213 90.2879 45.1213C89.4406 45.1657 88.598 44.9701 87.8566 44.5569C87.1151 44.1437 86.5047 43.5296 86.0953 42.7849H86.032V49.3994H83.2969V31.6274H85.9443V33.8485H85.9946C86.4229 33.1074 87.0436 32.4965 87.7907 32.0808C88.5378 31.665 89.3834 31.4601 90.2375 31.4877C93.6195 31.4877 95.8487 34.1669 95.8487 38.3052ZM93.0374 38.3052C93.0374 35.6016 91.6432 33.8241 89.516 33.8241C87.4262 33.8241 86.0206 35.639 86.0206 38.3052C86.0206 40.9959 87.4262 42.7979 89.516 42.7979C91.6432 42.7979 93.0374 41.0334 93.0374 38.3052Z"
          fill="white"
        />
        <path
          d="M105.542 39.8782C105.744 41.6946 107.505 42.8872 109.911 42.8872C112.217 42.8872 113.875 41.6945 113.875 40.0568C113.875 38.6351 112.875 37.7838 110.506 37.2004L108.138 36.6285C104.782 35.8161 103.223 34.2432 103.223 31.6908C103.223 28.5305 105.972 26.3597 109.874 26.3597C113.736 26.3597 116.383 28.5305 116.472 31.6908H113.711C113.546 29.8629 112.038 28.7595 109.835 28.7595C107.632 28.7595 106.124 29.8758 106.124 31.5006C106.124 32.7956 107.087 33.5576 109.443 34.1409L111.456 34.6364C115.206 35.5251 116.764 37.0347 116.764 39.7139C116.764 43.1406 114.04 45.2869 109.708 45.2869C105.655 45.2869 102.919 43.1911 102.742 39.8781L105.542 39.8782Z"
          fill="white"
        />
        <path
          d="M122.667 28.4672V31.6275H125.201V33.7982H122.667V41.1602C122.667 42.3039 123.174 42.8368 124.288 42.8368C124.589 42.8316 124.889 42.8104 125.188 42.7734V44.9312C124.687 45.025 124.178 45.0674 123.669 45.0579C120.971 45.0579 119.919 44.0424 119.919 41.4525V33.7982H117.981V31.6275H119.919V28.4672H122.667Z"
          fill="white"
        />
        <path
          d="M126.668 38.3053C126.668 34.1036 129.138 31.4633 132.988 31.4633C136.852 31.4633 139.309 34.1035 139.309 38.3053C139.309 42.5185 136.865 45.1473 132.988 45.1473C129.113 45.1473 126.668 42.5185 126.668 38.3053ZM136.522 38.3053C136.522 35.423 135.204 33.7218 132.988 33.7218C130.772 33.7218 129.455 35.436 129.455 38.3053C129.455 41.1991 130.772 42.8873 132.988 42.8873C135.204 42.8873 136.522 41.1991 136.522 38.3053Z"
          fill="white"
        />
        <path
          d="M141.564 31.6274H144.173V33.9004H144.236C144.413 33.1905 144.828 32.5631 145.411 32.1237C145.995 31.6844 146.712 31.4598 147.441 31.4877C147.757 31.4866 148.071 31.5209 148.379 31.59V34.1539C147.981 34.032 147.566 33.9761 147.15 33.9883C146.752 33.9721 146.356 34.0423 145.988 34.1941C145.621 34.3458 145.29 34.5755 145.019 34.8674C144.749 35.1593 144.544 35.5065 144.42 35.8851C144.296 36.2636 144.254 36.6647 144.299 37.0607V44.9816H141.564L141.564 31.6274Z"
          fill="white"
        />
        <path
          d="M160.989 41.0594C160.621 43.4836 158.266 45.1473 155.252 45.1473C151.375 45.1473 148.969 42.5444 148.969 38.3687C148.969 34.1799 151.388 31.4633 155.137 31.4633C158.823 31.4633 161.142 34.0013 161.142 38.0503V38.9895H151.73V39.1551C151.687 39.6466 151.748 40.1417 151.911 40.6076C152.073 41.0734 152.332 41.4994 152.671 41.8571C153.01 42.2149 153.421 42.4963 153.877 42.6826C154.333 42.8689 154.823 42.9558 155.315 42.9377C155.961 42.9984 156.61 42.8483 157.164 42.5099C157.719 42.1715 158.149 41.6628 158.392 41.0594L160.989 41.0594ZM151.743 37.0737H158.405C158.43 36.6318 158.363 36.1896 158.209 35.7748C158.055 35.36 157.817 34.9816 157.51 34.6634C157.203 34.3451 156.834 34.0939 156.425 33.9254C156.017 33.7569 155.578 33.6749 155.137 33.6844C154.691 33.6817 154.25 33.7675 153.838 33.9367C153.425 34.106 153.051 34.3554 152.735 34.6705C152.42 34.9856 152.17 35.3602 152 35.7727C151.829 36.1851 151.742 36.6273 151.743 37.0737Z"
          fill="white"
        />
        <path
          d="M55.6717 12.8782C56.2451 12.8369 56.8205 12.9237 57.3564 13.1323C57.8923 13.341 58.3753 13.6662 58.7706 14.0845C59.1659 14.5029 59.4637 15.0039 59.6423 15.5515C59.821 16.0992 59.8762 16.6797 59.8039 17.2513C59.8039 20.063 58.2876 21.6792 55.6717 21.6792H52.4996V12.8782H55.6717ZM53.8636 20.4346H55.5194C55.9291 20.4591 56.3392 20.3918 56.7197 20.2375C57.1002 20.0832 57.4416 19.8458 57.719 19.5425C57.9964 19.2393 58.2029 18.878 58.3234 18.4847C58.4438 18.0914 58.4753 17.6762 58.4155 17.2692C58.471 16.8638 58.4363 16.4512 58.314 16.0608C58.1916 15.6704 57.9847 15.312 57.7079 15.0112C57.4311 14.7104 57.0913 14.4747 56.713 14.3209C56.3346 14.1672 55.927 14.0992 55.5194 14.1219H53.8636V20.4346Z"
          fill="white"
        />
        <path
          d="M61.3447 18.3553C61.303 17.9188 61.3528 17.4785 61.491 17.0624C61.6291 16.6464 61.8524 16.2638 62.1467 15.9394C62.441 15.6149 62.7996 15.3556 63.1998 15.1782C63.5999 15.0008 64.0325 14.9092 64.4701 14.9092C64.9076 14.9092 65.3403 15.0008 65.7404 15.1782C66.1405 15.3556 66.4991 15.6149 66.7934 15.9394C67.0877 16.2638 67.311 16.6464 67.4492 17.0624C67.5873 17.4785 67.6371 17.9188 67.5954 18.3553C67.6379 18.7923 67.5886 19.2332 67.4509 19.65C67.3132 20.0667 67.0899 20.4499 66.7956 20.775C66.5012 21.1001 66.1423 21.3599 65.7418 21.5377C65.3413 21.7155 64.9081 21.8074 64.4701 21.8074C64.032 21.8074 63.5988 21.7155 63.1983 21.5377C62.7978 21.3599 62.4389 21.1001 62.1445 20.775C61.8502 20.4499 61.6269 20.0667 61.4892 19.65C61.3515 19.2332 61.3022 18.7923 61.3447 18.3553ZM66.2501 18.3553C66.2501 16.9156 65.6048 16.0737 64.4722 16.0737C63.3353 16.0737 62.6958 16.9156 62.6958 18.3553C62.6958 19.8066 63.3354 20.642 64.4722 20.642C65.6048 20.642 66.2501 19.8008 66.2501 18.3553Z"
          fill="white"
        />
        <path
          d="M75.9043 21.6792H74.5475L73.1778 16.7875H73.0743L71.7103 21.6792H70.3665L68.5397 15.0374H69.8663L71.0535 20.1054H71.1512L72.5138 15.0374H73.7685L75.1311 20.1054H75.2345L76.416 15.0374H77.7239L75.9043 21.6792Z"
          fill="white"
        />
        <path
          d="M79.2603 15.0375H80.5194V16.0926H80.6171C80.7829 15.7136 81.0626 15.3959 81.4171 15.1838C81.7716 14.9717 82.1833 14.8757 82.5948 14.9092C82.9173 14.885 83.2411 14.9337 83.5422 15.0518C83.8433 15.17 84.1141 15.3545 84.3344 15.5917C84.5547 15.829 84.7189 16.1129 84.8148 16.4223C84.9108 16.7318 84.936 17.059 84.8887 17.3796V21.6792H83.5808V17.7087C83.5808 16.6414 83.118 16.1106 82.1507 16.1106C81.9318 16.1004 81.7132 16.1377 81.51 16.22C81.3068 16.3024 81.1238 16.4278 80.9735 16.5877C80.8232 16.7475 80.7092 16.9381 80.6392 17.1463C80.5693 17.3544 80.545 17.5753 80.5682 17.7937V21.6793H79.2603L79.2603 15.0375Z"
          fill="white"
        />
        <path d="M86.9729 12.4446H88.2808V21.6791H86.9729V12.4446Z" fill="white" />
        <path
          d="M90.0989 18.3555C90.0572 17.9189 90.1071 17.4785 90.2452 17.0625C90.3834 16.6464 90.6068 16.2639 90.9011 15.9394C91.1954 15.6149 91.5541 15.3556 91.9542 15.1782C92.3543 15.0008 92.787 14.9092 93.2246 14.9092C93.6621 14.9092 94.0948 15.0008 94.495 15.1782C94.8951 15.3556 95.2538 15.6149 95.5481 15.9394C95.8424 16.2639 96.0658 16.6464 96.2039 17.0625C96.3421 17.4785 96.3919 17.9189 96.3503 18.3555C96.3927 18.7924 96.3434 19.2334 96.2057 19.6501C96.0679 20.0668 95.8446 20.4501 95.5502 20.7752C95.2559 21.1003 94.8969 21.36 94.4964 21.5378C94.0958 21.7156 93.6626 21.8074 93.2246 21.8074C92.7865 21.8074 92.3533 21.7156 91.9528 21.5378C91.5523 21.36 91.1933 21.1003 90.8989 20.7752C90.6046 20.4501 90.3813 20.0668 90.2435 19.6501C90.1057 19.2334 90.0565 18.7924 90.0989 18.3555ZM95.0043 18.3555C95.0043 16.9158 94.3589 16.0738 93.2264 16.0738C92.0895 16.0738 91.4499 16.9158 91.4499 18.3555C91.4499 19.8067 92.0895 20.6421 93.2264 20.6421C94.359 20.6421 95.0043 19.8009 95.0043 18.3555Z"
          fill="white"
        />
        <path
          d="M97.7273 19.8009C97.7273 18.6053 98.6155 17.9161 100.192 17.8181L101.987 17.7144V17.1411C101.987 16.4396 101.525 16.0435 100.631 16.0435C99.9005 16.0435 99.3946 16.3122 99.2494 16.7817H97.9831C98.1168 15.6409 99.1876 14.9092 100.691 14.9092C102.352 14.9092 103.29 15.7381 103.29 17.1411V21.6792H102.031V20.7458H101.927C101.717 21.0806 101.422 21.3535 101.072 21.5367C100.723 21.72 100.331 21.8069 99.9364 21.7886C99.6582 21.8176 99.377 21.7879 99.1109 21.7013C98.8449 21.6147 98.5999 21.4732 98.3918 21.2859C98.1837 21.0986 98.017 20.8697 97.9025 20.614C97.7881 20.3582 97.7284 20.0812 97.7273 19.8009ZM101.987 19.2333V18.678L100.369 18.7818C99.4563 18.843 99.0424 19.1541 99.0424 19.7396C99.0424 20.3374 99.5598 20.6853 100.271 20.6853C100.48 20.7064 100.69 20.6853 100.891 20.6232C101.091 20.5611 101.276 20.4593 101.436 20.3239C101.597 20.1884 101.728 20.0221 101.823 19.8347C101.917 19.6474 101.973 19.4428 101.987 19.2333Z"
          fill="white"
        />
        <path
          d="M105.009 18.3554C105.009 16.2567 106.085 14.9272 107.76 14.9272C108.174 14.908 108.585 15.0075 108.945 15.2138C109.305 15.4202 109.598 15.7249 109.792 16.0924H109.89V12.4446H111.197V21.6791H109.944V20.6298H109.841C109.632 20.9948 109.328 21.2958 108.961 21.5C108.595 21.7043 108.179 21.8041 107.76 21.7886C106.074 21.7887 105.009 20.4591 105.009 18.3554ZM106.36 18.3554C106.36 19.7641 107.022 20.6118 108.13 20.6118C109.233 20.6118 109.914 19.7519 109.914 18.3612C109.914 16.9769 109.226 16.1047 108.13 16.1047C107.029 16.1047 106.36 16.9582 106.36 18.3554Z"
          fill="white"
        />
        <path
          d="M116.609 18.3553C116.567 17.9188 116.617 17.4785 116.755 17.0624C116.893 16.6464 117.117 16.2638 117.411 15.9394C117.705 15.6149 118.064 15.3556 118.464 15.1782C118.864 15.0008 119.297 14.9092 119.734 14.9092C120.172 14.9092 120.604 15.0008 121.005 15.1782C121.405 15.3556 121.763 15.6149 122.058 15.9394C122.352 16.2638 122.575 16.6464 122.713 17.0624C122.851 17.4785 122.901 17.9188 122.86 18.3553C122.902 18.7923 122.853 19.2332 122.715 19.65C122.577 20.0667 122.354 20.4499 122.06 20.775C121.765 21.1001 121.407 21.3599 121.006 21.5377C120.605 21.7155 120.172 21.8074 119.734 21.8074C119.296 21.8074 118.863 21.7155 118.463 21.5377C118.062 21.3599 117.703 21.1001 117.409 20.775C117.114 20.4499 116.891 20.0667 116.753 19.65C116.616 19.2332 116.566 18.7923 116.609 18.3553ZM121.514 18.3553C121.514 16.9156 120.869 16.0737 119.736 16.0737C118.6 16.0737 117.96 16.9156 117.96 18.3553C117.96 19.8066 118.6 20.642 119.736 20.642C120.869 20.642 121.514 19.8008 121.514 18.3553Z"
          fill="white"
        />
        <path
          d="M124.615 15.0375H125.874V16.0926H125.971C126.137 15.7136 126.417 15.3959 126.771 15.1838C127.126 14.9717 127.537 14.8757 127.949 14.9092C128.271 14.885 128.595 14.9337 128.896 15.0518C129.198 15.17 129.468 15.3545 129.689 15.5917C129.909 15.829 130.073 16.1129 130.169 16.4223C130.265 16.7318 130.29 17.059 130.243 17.3796V21.6792H128.935V17.7087C128.935 16.6414 128.472 16.1106 127.505 16.1106C127.286 16.1004 127.067 16.1377 126.864 16.22C126.661 16.3024 126.478 16.4278 126.328 16.5877C126.177 16.7475 126.063 16.9381 125.993 17.1463C125.923 17.3544 125.899 17.5753 125.922 17.7937V21.6793H124.615V15.0375Z"
          fill="white"
        />
        <path
          d="M137.634 13.3838V15.0676H139.069V16.1717H137.634V19.587C137.634 20.2827 137.92 20.5874 138.571 20.5874C138.737 20.5868 138.904 20.5767 139.069 20.5571V21.6489C138.835 21.6911 138.597 21.7135 138.358 21.7159C136.903 21.7159 136.324 21.2031 136.324 19.9226V16.1717H135.272V15.0676H136.324V13.3838H137.634Z"
          fill="white"
        />
        <path
          d="M140.856 12.4446H142.152V16.1047H142.256C142.43 15.7222 142.717 15.4027 143.078 15.1898C143.44 14.9769 143.858 14.8809 144.276 14.9149C144.597 14.8974 144.918 14.9511 145.216 15.0721C145.513 15.1931 145.781 15.3785 145.999 15.615C146.217 15.8515 146.38 16.1333 146.477 16.4403C146.574 16.7473 146.602 17.0719 146.559 17.391V21.6792H145.25V17.7144C145.25 16.6535 144.757 16.1162 143.832 16.1162C143.608 16.0977 143.382 16.1287 143.17 16.207C142.958 16.2852 142.766 16.4089 142.607 16.5692C142.448 16.7296 142.326 16.9229 142.25 17.1355C142.173 17.3481 142.144 17.5749 142.164 17.8001V21.6791H140.856L140.856 12.4446Z"
          fill="white"
        />
        <path
          d="M154.185 19.886C154.007 20.493 153.622 21.018 153.097 21.3693C152.573 21.7206 151.941 21.8757 151.313 21.8075C150.877 21.819 150.443 21.7352 150.042 21.5619C149.641 21.3885 149.282 21.1298 148.991 20.8036C148.7 20.4774 148.483 20.0916 148.355 19.673C148.228 19.2543 148.192 18.8129 148.252 18.3793C148.194 17.9443 148.23 17.5019 148.357 17.082C148.484 16.6621 148.7 16.2746 148.99 15.9455C149.28 15.6165 149.637 15.3537 150.036 15.1749C150.436 14.9961 150.87 14.9055 151.308 14.9093C153.152 14.9093 154.264 16.1719 154.264 18.2575V18.7149H149.584V18.7884C149.564 19.0321 149.595 19.2774 149.674 19.5086C149.754 19.7399 149.881 19.9518 150.047 20.131C150.213 20.3101 150.415 20.4525 150.639 20.5489C150.863 20.6454 151.105 20.6938 151.349 20.6911C151.662 20.7288 151.979 20.6723 152.26 20.5289C152.541 20.3855 152.773 20.1617 152.926 19.8859L154.185 19.886ZM149.584 17.7455H152.932C152.948 17.5226 152.918 17.2987 152.843 17.0882C152.768 16.8777 152.65 16.6852 152.496 16.5232C152.343 16.3611 152.157 16.2331 151.951 16.1473C151.745 16.0615 151.523 16.0198 151.301 16.0249C151.074 16.0221 150.85 16.0646 150.64 16.1501C150.431 16.2356 150.24 16.3622 150.08 16.5226C149.921 16.6829 149.794 16.8737 149.709 17.0838C149.624 17.2938 149.581 17.5188 149.584 17.7455Z"
          fill="white"
        />
      </svg>
    </Link>
  )
}

export default BadgeApple
