import { Builder } from '@builder.io/react'
import CalculatorOdds from './calculatorOdds'

Builder.registerComponent(CalculatorOdds, {
  name: 'CalculatorOdds',
  inputs: [
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Convert American Odds to Implied Probability',
    },
    {
      name: 'oddsCalculatorDefaultAmericanOdds',
      friendlyName: 'Default American Odds',
      type: 'number',
      defaultValue: 100,
    },
    {
      name: 'oddsCalculatorDefaultImpliedProbability',
      friendlyName: 'Default Implied Probability',
      type: 'number',
      defaultValue: 50,
    },
  ],
})
