import styled from 'styled-components'

export const RichTextParagraph = styled.p`
  margin-bottom: 4rem;
  font-family: ${({ theme }) => theme.fontName};
  color: ${({ theme }) => theme.secondaryBlack};
  font-size: ${({ theme }) => theme.richText.paragraph};
  line-height: 1.5;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: ${({ theme }) => theme.richText.paragraphMedium};
  }
`

export const RichTextHeadingOne = styled.h1`
  margin-bottom: 4rem;
  color: ${({ theme }) => theme.secondaryBlack};
  font-family: ${({ theme }) => theme.fontName};
  font-size: ${({ theme }) => theme.richText.h1};
  font-weight: 700;
  line-height: 1.1;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: ${({ theme }) => theme.richText.h1Medium};
  }
`

export const RichTextHeadingTwo = styled.h2`
  margin-bottom: 4rem;
  color: ${({ theme }) => theme.secondaryBlack};
  font-family: ${({ theme }) => theme.fontName};
  font-size: ${({ theme }) => theme.richText.h2};
  font-weight: 700;
  line-height: 1.1;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: ${({ theme }) => theme.richText.h2Medium};
  }
`

export const RichTextHeadingThree = styled.h3`
  margin-bottom: 4rem;
  color: ${({ theme }) => theme.secondaryBlack};
  font-family: ${({ theme }) => theme.fontName};
  font-size: ${({ theme }) => theme.richText.h3};
  font-weight: 700;
  line-height: 1.1;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: ${({ theme }) => theme.richText.h3Medium};
  }
`

export const RichTextHeadingFour = styled.h4`
  margin-bottom: 4rem;
  color: ${({ theme }) => theme.secondaryBlack};
  font-family: ${({ theme }) => theme.fontName};
  font-size: ${({ theme }) => theme.richText.h4};
  font-weight: 700;
  line-height: 1.1;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: ${({ theme }) => theme.richText.h4Medium};
  }
`

export const RichTextHyperlink = styled.a`
  transition: 0.5s color ease;
  color: ${({ theme }) => theme.primaryGreen};
  font-family: ${({ theme }) => theme.fontName};
  font-weight: 700;
  text-decoration: none;
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.secondaryGreen};
  }
`

export const RichTextUl = styled.ul`
  margin-left: 3rem;
  margin-bottom: 4rem;
  li {
    position: relative;
    margin-bottom: 3rem;
    font-weight: 700;
  }
  li > p {
    margin-bottom: 0rem;
  }

  li::before {
    position: absolute;
    content: '•';
    color: ${({ theme }) => theme.primaryBlue};
    font-weight: bold;
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15px;
    width: 15px;
    margin-left: -1em;
    top: calc(calc(${({ theme }) => theme.richText.paragraph} / 2) - 0.3rem);
    @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
      top: calc(calc(${({ theme }) => theme.richText.paragraphMedium} / 2) - 0.3rem);
    }
  }
`

export const RichTextOl = styled.ol`
  margin-left: 3rem;
  margin-bottom: 4rem;
  list-style: none;
  counter-reset: custom-counter;
  li {
    position: relative;
    margin-bottom: 3rem;
    counter-increment: custom-counter;
  }
  li > p {
    margin-bottom: 0rem;
  }
  & div ol {
    margin-bottom: 0;
  }
  li::before {
    position: absolute;
    margin-left: -2em;
    content: counter(custom-counter) '. ';
    color: ${({ theme }) => theme.primaryBlue};
    font-family: ${({ theme }) => theme.fontName};
    font-weight: 700;
    font-size: 1.8rem;
  }
`
