import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

interface FooterStyledProps {
  active?: boolean
  $showForm?: boolean
}

export const Component = styled.footer<FooterStyledProps>`
  padding-top: 7rem;
  padding-bottom: 7.2rem;
  background-color: ${({ theme }) => theme.primaryBlack};
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding-top: ${({ $showForm }) => ($showForm ? '0' : '7rem')};
    padding-bottom: 4.6rem;
  }
`

export const Container = styled.div`
  max-width: ${({ theme }) => theme.widthContainer};
  margin: 0 auto;
  padding: 0 0.5rem;
`

export const Body = styled.div`
  margin: 0 auto 3.8rem auto;
`

export const Group = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-left: 2rem;
  }
`

export const BodyTitle = styled.div`
  margin-bottom: 1.4rem;
  color: ${({ theme }) => theme.primaryWhite};
`

export const BodyText = styled.div`
  opacity: 0.6;
  color: ${({ theme }) => theme.primaryWhite};
`

export const Form = styled.div`
  align-self: center;
  margin-left: 5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin: 0;
    text-align: center;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 30rem;
  }
`

export const SocialContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: column;
    margin: 3.2rem;
  }
`

export const SocialTitle = styled.div`
  color: ${({ theme }) => theme.primaryWhite};

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: 20px;
    margin-bottom: 1rem;
  }
`

export const Ribbon = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 4rem 0;
  border-top: 1px solid ${({ theme }) => theme.primaryWhite};
  border-bottom: 1px solid ${({ theme }) => theme.primaryWhite};

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    border-top: 0;
    padding: 0;
    flex-direction: column;
  }
`

export const RibbonNavigation = styled.nav`
  display: flex;

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    border-top: 1px solid ${({ theme }) => theme.primaryWhite};
    padding: 3.2rem 0;
    justify-content: center;
    width: 100%;
  }
`

export const RibbonNavigationList = styled.ul`
  display: flex;
`

export const RibbonNavigationListItem = styled.li`
  a {
    margin: 2.5rem;
    color: ${({ theme }) => theme.primaryWhite};
    text-decoration: none;
  }
`

export const Base = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export const Copyright = styled.div`
  margin-bottom: 2rem;
  opacity: 0.6;
  color: ${({ theme }) => theme.primaryWhite};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 1.4rem;
  text-align: center;
`

export const BaseLockupList = styled.ul`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    justify-content: center;
    padding-bottom: 2rem;
  }
`

export const LegalList = styled.ul`
  display: flex;
  margin: 4rem 0 2rem;
`

export const LegalListItem = styled.li`
  padding: 0 0.5rem;
`

export const BaseLockupListItem = styled.li`
  &:first-of-type {
    width: 100%;
    margin-bottom: 1rem;
    @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
      width: auto;
      margin: 1rem 0;
    }
  }
  &:last-of-type {
    border-right: 0 !important;
    padding-right: 0 !important;
  }
  &:not(:first-of-type) {
    width: 50%;
    margin: 1rem 0;
    border-right: 1px solid #a7abad;
    @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
      width: auto;
    }
  }
  &:not(:last-of-type) {
    @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
      margin-right: 1rem;
      padding-right: 1rem;
      border-right: 1px solid #a7abad;
    }
  }
`

export const BaseLockupLinks = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    justify-content: flex-end;
    align-items: flex-end;
    width: 60%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    width: 50%;
  }
`

const linkStyles = css`
  transition: 0.5s ease color;
  opacity: 0.6;
  color: ${({ theme }) => theme.primaryWhite};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 1.4rem;
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.primaryGreen};
  }
`

export const BaseLockupListItemLink = styled(Link)`
  ${linkStyles}
`

export const BaseLockupListItemExternal = styled.a`
  ${linkStyles}
`

export const DropUpText = styled.span`
  transition: 0.5s ease color;
  margin-right: 0.6rem;
  opacity: 0.6;
  color: ${({ theme }) => theme.primaryWhite};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 1.4rem;
  text-decoration: underline;
`

export const DropUp = styled.button`
  position: relative;
  &:hover,
  &:focus {
    cursor: pointer;
    ${DropUpText} {
      color: ${({ theme }) => theme.primaryGreen};
    }
  }
`

export const DropUpViewport = styled.div<FooterStyledProps>`
  transition: 0.2s ease-in-out opacity;
  height: ${(props) => (props.active ? 'auto' : '0')};
  overflow: ${(props) => (props.active ? 'unset' : 'hidden')};
  opacity: ${(props) => (props.active ? '1' : '0')};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  position: absolute;
  right: 0;
  bottom: 3rem;
  background: ${({ theme }) => theme.tertiaryBlack};
  width: 23.7rem;
  min-height: 9rem;
  border-radius: 1.5rem;
  padding: 3.2rem;
  text-align: left;
`

export const DropUpViewportListItem = styled.li`
  margin-bottom: 0.8rem;
`

export const DropUpViewportLink = styled(Link)`
  transition: 0.5s ease opacity;
  opacity: 0.6;
  color: ${({ theme }) => theme.primaryWhite};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 1.4rem;
  text-decoration: none;
  &:hover,
  &:focus {
    opacity: 1;
  }
`

export const DropUpIcon = styled.span<FooterStyledProps>`
  svg {
    transition: 0.5s ease all;
    opacity: 0.6;
    transform: ${(props) => (props.active ? 'rotate(0deg)' : 'rotate(180deg);')};
    fill: ${(props) => (props.active ? ({ theme }) => theme.primaryGreen : ({ theme }) => theme.primaryWhite)};
  }
`

export const SectionOne = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 9rem 2rem 15rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;
  }
`

export const DownloadContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    text-align: center;
  }

  @media (max-width: 700px) {
    flex-direction: column;
  }
`

export const Badges = styled.div`
  margin-top: 2rem;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  width: 42rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: row;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 8rem;
    width: 20rem;
    height: 15rem;
    align-self: center;
  }
`

export const TheCopyright = styled.div`
  display: block;
  opacity: 0.6;
  color: ${({ theme }) => theme.primaryWhite};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 1.4rem;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 0;
  }
`
