import React, { FormEvent, useState } from 'react'
import { getRecaptchaToken, sendText } from '../../helpers/api'
import {
  Button,
  ColumnOne,
  ColumnTwo,
  Container,
  Drawer,
  ErrorIcon,
  ErrorState,
  ErrorText,
  Grid,
  Input,
  Label,
} from './textForm.styles'
import { parseBuilderRichText } from '../../helpers/builderRichTextParser'

interface TextFormProps {
  drawerText: string
}

function TextForm({ drawerText }: TextFormProps) {
  const [phone, setPhone] = useState('')
  const [focused, setFocused] = useState(false)
  const [error, setError] = useState(false)

  const handlePhoneChange = (event: { target: HTMLInputElement }) => {
    setPhone(event.target.value)
    setFocused(false)
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    try {
      if (typeof window !== undefined) {
        const token = await getRecaptchaToken(process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY, window.grecaptcha)
        const response = await sendText(phone, token)

        if (response.error) {
          setError(true)
          console.log('error')
        } else {
          setError(false)
          window.location.href = '/download-confirmation/'
        }
      }
    } catch (error) {
      console.log('error')
    }
  }

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid>
            <ColumnOne>
              <Label htmlFor="phone">Text me a link: </Label>
              <Input
                onFocus={() => setFocused(true)}
                onBlur={handlePhoneChange}
                type="tel"
                name="phone"
                id="phone"
                minLength={10}
                maxLength={11}
                placeholder="Phone Number"
                required
              />
            </ColumnOne>
            <ColumnTwo>
              <Button type="submit">Send Link</Button>
            </ColumnTwo>
          </Grid>
          {error && (
            <ErrorState>
              <ErrorText>
                <ErrorIcon>
                  <svg width="5" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.117.914l-.36 8.622h-2.61L.787.914h3.33zm-1.62 13.23c-.54 0-.984-.156-1.332-.468a1.587 1.587 0 01-.504-1.188c0-.48.168-.882.504-1.206.348-.324.792-.486 1.332-.486.528 0 .96.162 1.296.486.348.324.522.726.522 1.206 0 .468-.174.864-.522 1.188-.336.312-.768.468-1.296.468z"
                      fill="#fff"
                    />
                  </svg>
                </ErrorIcon>
                Something went wrong! Please try again.
              </ErrorText>
            </ErrorState>
          )}
          <Drawer $focused={focused}>{parseBuilderRichText(drawerText)}</Drawer>
        </form>
      </Container>
    </>
  )
}

export default TextForm
