import styled, { keyframes } from 'styled-components'

export const Tada = keyframes`
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    -ms-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
`

export const Component = styled.section`
  position: relative;
  margin-bottom: 35rem;
  padding: 20rem 0 25rem;
  background: ${({ theme }) => theme.secondaryWhite};

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 0rem;
    padding: 20rem 0 10rem;
  }
`

export const Container = styled.div`
  max-width: ${({ theme }) => theme.widthContainer};
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.widthDocumentGutter};
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    display: flex;
  }
`

export const Body = styled.div`
  margin-bottom: 0;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    width: 50%;
    margin-bottom: 5.9rem;
    text-align: left;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.extraLarge}) {
    width: 70%;
  }
`

export const Logo = styled.div`
  display: none;
  height: 7.5rem;
  margin-bottom: 4.8rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    display: block;
  }
`

export const Title = styled.h1`
  margin: 0 auto 1.1rem auto;
  color: ${({ theme }) => theme.primaryBlack};
`

export const SubText = styled.div`
  max-width: 50rem;
  margin: 0 auto 30rem auto;
  color: ${({ theme }) => theme.secondaryBlack};
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin: inherit;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.extraLarge}) {
    max-width: 66rem;
  }
`

export const ArtworkContainer = styled.div`
  display: flex;
  justify-content: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    position: relative;
    flex-grow: 1;
  }
`

export const Artwork = styled.div`
  position: absolute;
  top: 18rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 250px;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    top: 28rem;
    width: 350px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    top: 0;
    left: auto;
    margin: 0;
    width: auto;
  }
`

export const NoticeWrapper = styled.div`
  position: absolute;
  left: 2.1rem;
  right: 2.1rem;
  z-index: 120;
  padding: 3.8rem 4.7rem 4.7rem 4.7rem;
  border-radius: 2rem;
  background: ${({ theme }) => theme.primaryWhite};
  box-shadow:
    0px 16px 16px rgba(50, 50, 71, 0.08),
    0px 24px 32px rgba(50, 50, 71, 0.08);
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    position: relative;
    left: auto;
    right: auto;
    padding: 0;
    background: transparent;
    border-radius: 0;
    box-shadow: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    text-align: left;
  }
`

export const Notice = styled.div`
  position: relative;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 4.1rem;
    padding: 4.8rem 8.3rem 0;
    border-radius: 2rem;
    background: ${({ theme }) => theme.primaryWhite};
    box-shadow:
      0px 16px 16px rgba(50, 50, 71, 0.08),
      0px 24px 32px rgba(50, 50, 71, 0.08);
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    max-width: 70rem;
  }
`

export const NoticeTitle = styled.p`
  margin-bottom: 0.7rem;
  color: ${({ theme }) => theme.tertiaryBlack};
  font-size: 2.2rem;
  font-family: ${({ theme }) => theme.fontName};
  font-weight: 800;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 0;
    font-size: 3.6rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    text-align: left;
  }
`

export const NoticeText = styled.div`
  p {
    max-width: 50rem;
    margin: 0 auto 3.2rem auto;
    color: #1d1d1d;
    font-size: 1.5rem;
    font-family: ${({ theme }) => theme.fontName};
    font-weight: 400;
    text-align: center;

    @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
      text-align: left;
      font-size: 1.8rem;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
      margin: 0 0 4.5rem 0;
    }
  }
`

export const NoticeSubTitle = styled.p`
  margin-bottom: 1.6rem;
  color: ${({ theme }) => theme.secondaryBlack};
  font-size: 1.8rem;
  font-family: ${({ theme }) => theme.fontName};
  font-weight: 700;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-left: 1rem;
  }
`

export const Badges = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-top: 8rem;
    margin-left: 8.3rem;
    max-width: 43rem;
  }
`

export const BadgeGrid = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: row;
  }
`

export const BadgeColumn = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 50%;
  }
`

export const Icon = styled.div`
  margin-bottom: 2.7rem;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    position: absolute;
    left: -40px;
    top: 50px;
  }
`

export const IconArtwork = styled.img`
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: ${Tada};
`

export const BadgeFirst = styled.div`
  margin-bottom: 2rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 0;
  }
`
