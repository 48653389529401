import React from 'react'
import { StyledTitle } from './titleSix.styles'

interface TitleSixProps {
  tag: any
  text: string | []
}

const TitleSix = ({ tag, text }: TitleSixProps) => {
  return <StyledTitle as={tag}>{text}</StyledTitle>
}

export default TitleSix
