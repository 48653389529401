import { Builder } from '@builder.io/react'
import Hero from './hero'
import { GlobalID, LogoID } from '../../types/builderio-ids'

Builder.registerComponent(Hero, {
  name: 'Hero',
  inputs: [
    {
      name: 'globalData',
      friendlyName: 'Global Data',
      type: 'reference',
      required: true,
      model: 'global' satisfies GlobalID,
      options: {
        includeRefs: true,
        includeSubRefs: true,
        enrich: true,
      },
    },
    {
      name: 'homePageData',
      friendlyName: 'Hero Data',
      type: 'object',
      defaultValue: {
        heroTitleOne: 'First Title',
        heroTitleTwo: 'Second Title',
        heroText: '<p>Long text here</p>',
        heroNoticeTitle: 'Download now',
        heroNoticeText: '<p>Available on the App Store &amp; Google Play to anyone 21+</p>',
        heroNoticeArtwork:
          'https://cdn.builder.io/api/v1/image/assets%2Ff127073fccdc44a590bf4362d8bb100b%2Fb9c36bf298ea4f58a2fbb6b2ed6d47af',
        heroArtwork:
          'https://cdn.builder.io/api/v1/image/assets%2Ff127073fccdc44a590bf4362d8bb100b%2F6b7798096f254fc5a741f404a071a990',
      },
      subFields: [
        {
          name: 'heroNoticeArtwork',
          friendlyName: 'Hero Sporttrade Artwork',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
          required: true,
        },
        {
          name: 'heroTitleOne',
          friendlyName: 'Hero First Title',
          type: 'string',
          required: true,
        },
        {
          name: 'heroTitleTwo',
          friendlyName: 'Hero Second Title',
          type: 'string',
          required: true,
        },
        {
          name: 'heroText',
          friendlyName: 'Hero Long Text',
          type: 'richText',
          required: true,
        },
        {
          name: 'heroArtwork',
          friendlyName: 'Hero Artwork',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
          required: true,
        },
        {
          name: 'heroNoticeTitle',
          friendlyName: 'Hero Notice Title',
          type: 'string',
          required: true,
        },
        {
          name: 'heroNoticeText',
          friendlyName: 'Hero Notice Text',
          type: 'richText',
          required: true,
        },
        {
          name: 'sporttradeLogo',
          friendlyName: 'Sporttrade Logo',
          type: 'reference',
          model: 'logo' satisfies LogoID,
          required: true,
        },
      ],
    },
  ],
})
