import React, { createContext, useContext, useEffect, useState } from 'react'

import { getMarketData } from '../helpers/api'
import { DataFeedStructure, IMarketData } from '../types/dataFeed'
import { Contest, League, Market, MessageEventData, MessageType, Outcome } from '../types/refData'
import { LookupObject } from '../types/parsing'
import { arrayToObject, getOutcomeIdsForMarketData, parseDataFeed } from '../utils'

interface MarketDataState {
  dataFeedCards: DataFeedStructure
}

export const MarketDataContext = createContext<MarketDataState>({
  dataFeedCards: {
    NFL: [],
    NBA: [],
    WNBA: [],
    MLB: [],
    NCAAF: [],
    NCAAB: [],
    NHL: [],
  },
})

interface MarketDataProviderProps {
  children: React.ReactNode
}

export const MarketDataProvider: React.FC<MarketDataProviderProps> = ({ children }) => {
  const [outcomeIds, setOutcomeIds] = useState<string[]>([]),
    [leagueLookup, setLeagueLookup] = useState<LookupObject<League>>({}),
    [contestLookup, setContestLookup] = useState<LookupObject<Contest>>({}),
    [marketLookup, setMarketLookup] = useState<LookupObject<Market>>({}),
    [outcomeLookup, setOutcomeLookup] = useState<LookupObject<Outcome>>({}),
    [marketData, setMarketData] = useState<IMarketData[]>([]),
    [dataFeedCards, setDataFeedCards] = useState<DataFeedStructure>({
      NFL: [],
      NBA: [],
      WNBA: [],
      MLB: [],
      NCAAF: [],
      NCAAB: [],
      NHL: [],
    })

  useEffect(() => {
    const source = new EventSource(`${process.env.GATSBY_EDGE_STREAM_URL}`)

    source.addEventListener('message', (event) => {
      const data = JSON.parse(event.data) as MessageEventData

      switch (data.message_type) {
        case MessageType.LEAGUE_SNAPSHOT: {
          setLeagueLookup(arrayToObject(data.leagues, 'leagueId'))
          break
        }
        case MessageType.CONTEST_SNAPSHOT: {
          setContestLookup(arrayToObject(data.contests, 'contestId'))
          break
        }
        case MessageType.MARKET_SNAPSHOT: {
          setMarketLookup(arrayToObject(data.markets, 'marketId'))
          break
        }
        case MessageType.OUTCOME_SNAPSHOT: {
          setOutcomeLookup(arrayToObject(data.outcomes, 'outcomeId'))
          source.close()
          break
        }
        default:
          break
      }
    })

    source.addEventListener('error', (event) => {
      console.error('EventSource error: ', event)
    })

    return () => {
      if (source.readyState === 1) {
        source.close()
      }
    }
  }, [])

  useEffect(() => {
    const outcomes = Object.values(outcomeLookup)
    if (outcomes.length > 0) {
      const outcomeIds = getOutcomeIdsForMarketData(contestLookup, leagueLookup, marketLookup, outcomes)
      setOutcomeIds(outcomeIds)
    }
  }, [outcomeLookup])

  useEffect(() => {
    if (outcomeIds.length > 0) {
      const run = async () => {
        const response = await getMarketData(outcomeIds)
        setMarketData(response)
      }
      run()
    }

    return () => {}
  }, [outcomeIds])

  useEffect(() => {
    if (marketData.length > 0) {
      const parsedDataFeed = parseDataFeed({
        leagueLookup,
        contestLookup,
        marketLookup,
        outcomeLookup,
        marketData,
      })

      setDataFeedCards(parsedDataFeed)
    }
  }, [marketData])

  return <MarketDataContext.Provider value={{ dataFeedCards }}>{children}</MarketDataContext.Provider>
}

export const useMarketData = () => useContext(MarketDataContext)
