import React from 'react'
import CalloutText from '../CalloutText/calloutText'
import TitleSix from '../TitleSix/titleSix'
import {
  Component,
  Container,
  Header,
  HeaderOne,
  HeaderThree,
  HeaderTwo,
  IconAlert,
  IconHighlight,
  One,
  Row,
  Three,
  Title,
  TitleBreak,
  Two,
} from './matrix.styles'
import { BuilderIoReference, FeaturesModel } from '../../types/builderio-models'

type MatrixProps =
  | {
      features: {
        feature: BuilderIoReference<FeaturesModel, 'data'>
      }[]
      logoUrl: string
      builderBlock?: never
      builderState?: never
    }
  | {
      features: {
        feature: FeaturesModel
      }[]
      logoUrl: string
      builderBlock: any
      builderState: any
    }

const Matrix = (props: MatrixProps) => {
  const { features, logoUrl } = props
  return (
    <Component>
      <Container>
        <Header>
          <HeaderOne>
            <CalloutText tag="p" text="Sporttrade features" />
          </HeaderOne>
          <HeaderTwo>
            <img src={logoUrl} width={156} height={62}></img>
          </HeaderTwo>
          <HeaderThree>
            <Title>
              <TitleBreak>Sports</TitleBreak>books
            </Title>
          </HeaderThree>
        </Header>
        {features.map(({ feature }, i) => {
          const { title } = props?.builderBlock
            ? (feature as FeaturesModel)
            : (feature as BuilderIoReference<FeaturesModel, 'data'>).value.data
          return (
            <Row key={i}>
              <One>
                <TitleSix tag="p" text={title} />
              </One>
              <Two>
                <IconHighlight>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      opacity=".94"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.544 11.428c.39 0 .696-.156.91-.474l6.085-9.387c.156-.234.221-.449.221-.65 0-.533-.39-.917-.936-.917-.377 0-.605.13-.832.494l-5.474 8.66L1.723 5.63c-.221-.267-.449-.39-.774-.39-.552 0-.949.39-.949.93 0 .233.078.454.28.688l3.36 4.128c.254.306.534.442.904.442z"
                      fill="#fff"
                    />
                  </svg>
                </IconHighlight>
              </Two>
              <Three>
                <IconAlert>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.44.621a.75.75 0 010 1.06l-9.85 9.85A.75.75 0 01.53 10.47L10.38.62a.75.75 0 011.06 0z"
                      fill="#fff"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.47 11.53a.75.75 0 01-1.061 0L.56 1.682A.75.75 0 011.621.622l9.849 9.848a.75.75 0 010 1.06z"
                      fill="#fff"
                    />
                  </svg>
                </IconAlert>
              </Three>
            </Row>
          )
        })}
      </Container>
    </Component>
  )
}
export default Matrix
