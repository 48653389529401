import PropTypes from 'prop-types'
import React from 'react'
import { StyledTitle } from './titleTwo.styles'

interface TitleTwoProps {
  tag: any
  text: string
}

const TitleTwo = ({ tag, text }: TitleTwoProps) => {
  return <StyledTitle as={tag}>{text}</StyledTitle>
}

export default TitleTwo
