import styled from 'styled-components'

export const StyledTitle = styled.span`
  font-family: ${({ theme }) => theme.fontName};
  font-size: ${({ theme }) => theme.fontSizes.h3};
  font-weight: 700;
  line-height: 1.1;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: ${({ theme }) => theme.fontSizes.h3Medium};
  }
`
