import styled from 'styled-components'

export const SocialList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const SocialListItem = styled.li`
  margin: 1.25rem;
`

export const SocialIcon = styled.a`
  transition: 0.5s ease background;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: ${({ theme }) => theme.primaryGreen};
  width: 3.5rem;
  height: 3.5rem;
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.secondaryGreen};
  }
`

export const ScreenReader = styled.span`
  position: absolute;
  left: -999em;
  right: auto;
`

export const SocialTitle = styled.div`
  color: ${({ theme }) => theme.primaryWhite};

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 1rem;
  }
`
