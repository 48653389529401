import styled from 'styled-components'

interface BadgeAppleStyledProps {
  $inverse: boolean
}

export const Link = styled.a<BadgeAppleStyledProps>`
  display: block;
  background: ${(props) => (props.$inverse ? 'transparent' : '#232C33')};
  height: 6.4rem;
  border-radius: 5px;
`
