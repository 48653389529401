import styled from 'styled-components'

export const Paragraph = styled.p`
  margin-bottom: 4rem;
  font-family: ${({ theme }) => theme.fontName};
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.6;
  b {
    font-weight: 800;
  }
  i {
    font-style: italic;
    font-weight: 500;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: 1.8rem;
  }
`

export const A = styled.a<{ isDarkMode?: boolean }>`
  transition: 0.5s color ease;
  color: ${({ theme, isDarkMode }) => (isDarkMode ? theme.primaryGreenDarkMode : theme.primaryGreen)};
  font-family: ${({ theme }) => theme.fontName};
  font-weight: 700;
  text-decoration: none;
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.secondaryGreen};
  }
`

export const Ul = styled.ul`
  margin-left: 3rem;
  margin-bottom: 4rem;
  li {
    position: relative;
    font-weight: 700;
    > p {
      margin-bottom: 2rem;
    }
  }
  li::before {
    position: absolute;
    content: '•';
    color: ${({ theme }) => theme.primaryBlue};
    font-weight: bold;
    font-size: 3rem;
    display: inline-block;
    width: 15px;
    top: -0.6rem;
    margin-left: -1em;
  }
`

export const Ol = styled.ol`
  margin-left: 3rem;
  margin-bottom: 4rem;
  list-style: none;
  counter-reset: custom-counter;
  li {
    position: relative;
    counter-increment: custom-counter;
    > p {
      margin-bottom: 2rem;
    }
  }
  li::before {
    position: absolute;
    margin-left: -2em;
    content: counter(custom-counter) '. ';
    color: ${({ theme }) => theme.primaryBlue};
    font-family: ${({ theme }) => theme.fontName};
    font-weight: 700;
    font-size: 1.8rem;
  }
`

export const H2 = styled.h2`
  margin-bottom: 4rem;
  font-family: ${({ theme }) => theme.fontName};
  font-size: 2.6rem;
  font-weight: 700;
  line-height: 1.1;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: 4rem;
  }
`

export const H3 = styled.h3`
  margin-bottom: 5.2rem;
  font-family: ${({ theme }) => theme.fontName};
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.4;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: 2.6rem;
  }
`

export const H4 = styled.h4`
  margin-bottom: 4rem;
  font-family: ${({ theme }) => theme.fontName};
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.1;
`
