import React from 'react'
import Subtitle from '../Subtitle/subtitle'
import { SocialList, SocialListItem, SocialIcon, ScreenReader, SocialTitle } from './social.styles'
import { BuilderIoReference, LogoModel } from '../../types/builderio-models'

interface SocialProps {
  socialIcons: {
    socialIcon: BuilderIoReference<LogoModel, 'data'>
  }[]
  socialIconsTitle?: string
}
const Social = ({ socialIcons, socialIconsTitle }: SocialProps) => {
  return (
    <>
      {socialIconsTitle && (
        <SocialTitle>
          <Subtitle tag="p" text={socialIconsTitle} />
        </SocialTitle>
      )}
      <SocialList>
        {socialIcons.map(({ socialIcon }) => {
          const socialIconData = socialIcon.value.data
          return (
            <SocialListItem key={socialIconData.title}>
              <SocialIcon href={socialIconData.url} target="_blank" rel="noreferrer nofollow">
                <ScreenReader>{socialIconData.title}</ScreenReader>
                <img src={socialIconData.image.file} />
              </SocialIcon>
            </SocialListItem>
          )
        })}
      </SocialList>
    </>
  )
}

export default Social
