import React, { useEffect, useState } from 'react'
import video from '../../assets/website-hero.mp4'
import { Wrapper, Video, StaticImage, Svg } from './screenVideo.styles'

interface ScreenVideoProps {
  artwork: { description: string; url: string }
}

const ScreenVideo = ({ artwork }: ScreenVideoProps) => {
  const [width, setWidth] = useState(0)
  const breakpoint = 600

  useEffect(() => {
    setWidth(window.innerWidth)
    window.addEventListener('resize', () => setWidth(window.innerWidth))
    return () => {
      window.removeEventListener('resize', () => setWidth(window.innerWidth))
    }
  }, [])

  return (
    <Wrapper>
      {width > breakpoint ? (
        <Video id="video" autoPlay loop muted playsInline>
          <source src={video} type="video/mp4" />
        </Video>
      ) : (
        <StaticImage src={artwork.url} alt={artwork.description} />
      )}
      <Svg viewBox="0 0 431 862" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 50C3 22.3857 25.3858 0 53 0H378C405.614 0 428 22.3858 428 50V192C429.657 192 431 193.343 431 195V289C431 290.657 429.657 292 428 292V812C428 839.614 405.614 862 378 862H53C25.3858 862 3 839.614 3 812V313C1.3431 313 0 311.657 0 310V253C0 251.343 1.3431 250 3 250V234C1.3431 234 0 232.657 0 231V174C0 172.343 1.3431 171 3 171V137C1.8954 137 1 136.105 1 135V106C1 104.895 1.8954 104 3 104V50ZM58 28C41.4315 28 28 41.4315 28 58V810C28 826.569 41.4314 840 58 840H373C389.569 840 403 826.569 403 810V58C403 41.4315 389.569 28 373 28H58Z"
          fill="#242A31"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56 5H375C401.51 5 423 26.4903 423 53V809C423 835.51 401.51 857 375 857H56C29.4903 857 8 835.51 8 809V53C8 26.4903 29.4903 5 56 5ZM58 28C41.4315 28 28 41.4315 28 58V810C28 826.569 41.4314 840 58 840H373C389.569 840 403 826.569 403 810V58C403 41.4315 389.569 28 373 28H58Z"
          fill="#13151B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M372 8H59C32.4903 8 11 29.4903 11 56V806C11 832.51 32.4903 854 59 854H372C398.51 854 420 832.51 420 806V56C420 29.4903 398.51 8 372 8ZM58 28C41.4315 28 28 41.4315 28 58V810C28 826.569 41.4314 840 58 840H373C389.569 840 403 826.569 403 810V58C403 41.4315 389.569 28 373 28H58Z"
          fill="#242A31"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M135 25H127C131.8 25 135 26.5 135 31V37C135 46.9411 143.059 55 153 55H277C286.941 55 295 46.9411 295 37V31C295 26.5 298.2 25 303 25H295H135Z"
          fill="#242A31"
        />
        <path
          d="M259 42C262.866 42 266 38.866 266 35C266 31.134 262.866 28 259 28C255.134 28 252 31.134 252 35C252 38.866 255.134 42 259 42Z"
          fill="#13151B"
        />
        <path
          d="M259 39C261.209 39 263 37.2091 263 35C263 32.7909 261.209 31 259 31C256.791 31 255 32.7909 255 35C255 37.2091 256.791 39 259 39Z"
          fill="#242A31"
        />
        <path
          d="M236 31H195C192.791 31 191 32.7909 191 35C191 37.2091 192.791 39 195 39H236C238.209 39 240 37.2091 240 35C240 32.7909 238.209 31 236 31Z"
          fill="#13151B"
        />
      </Svg>
    </Wrapper>
  )
}

export default ScreenVideo
