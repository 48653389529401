import styled from 'styled-components'

export const StyledTitle = styled.span`
  font-family: ${({ theme }) => theme.fontName};
  font-size: ${({ theme }) => theme.fontSizes.h2};
  line-height: 1.1;
  font-weight: 800;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: ${({ theme }) => theme.fontSizes.h2Medium};
  }
`
