import { IBook, IBookPriceLevel, IMarketData, IPriceLevel } from '../types/dataFeed'

const parseLevels = (levels: IBookPriceLevel): IPriceLevel[] =>
  Object.entries(levels).map(([price, quantity]) => ({ price: +price / 10000, quantity: +quantity }))

export const parseBook = (book: IBook): IMarketData[] => {
  const data = Object.entries(book).map(([outcomeId, { bidLevels, askLevels }]) => ({
    outcomeId,
    bidLevels: parseLevels(bidLevels),
    askLevels: parseLevels(askLevels),
  }))

  return data
}
