import styled from 'styled-components'

export const StyledTitle = styled.span`
  font-family: ${({ theme }) => theme.fontName};
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.6;
  b {
    font-weight: 800;
  }
  i {
    font-style: italic;
    font-weight: 500;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: 1.8rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.extraLarge}) {
    font-size: 2rem;
  }
`
