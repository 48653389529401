import styled from 'styled-components'
import { Link } from 'gatsby'

export const ChevronIcon = styled.svg``

interface VideoIconProps {
  $active?: boolean
}

export const VideoIcon = styled.svg<VideoIconProps>`
  transition: 0.2s fill ease;
  margin-left: 1.5rem;
  fill: ${(props) => (props.$active ? ({ theme }) => theme.primaryBlue : '#000')};
`

interface ComponentProps {
  $spaced?: boolean
}
export const Component = styled.section<ComponentProps>`
  scroll-margin: 300px;
  margin-bottom: ${(props) => (props.$spaced ? '10rem' : '3rem')};
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: ${(props) => (props.$spaced ? '17.3rem' : '7.5rem')};
  }
`

export const Container = styled.div`
  max-width: ${({ theme }) => theme.widthContainer};
  margin: 0 auto;
`

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: row;
  }
`

export const Aside = styled.div`
  margin-bottom: 5.8rem;
  color: ${({ theme }) => theme.primaryBlack};
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 30%;
    padding-right: 5rem;
  }
`

interface BodyProps {
  $title?: string
  $layoutDefault?: boolean
}
export const Body = styled.div<BodyProps>`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: ${(props) => (props.$title && !props.$layoutDefault ? '70%' : '100%')};
  }
`

export const Collapse = styled.div`
  border-top: 1px solid ${({ theme }) => theme.secondaryWhite};
  &:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.secondaryWhite};
  }
`

interface CollapseTriggerProps {
  $active?: boolean
}

export const CollapseTrigger = styled.button<CollapseTriggerProps>`
  transition: 0.2s color ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 3.5rem 1rem;
  color: ${(props) => (props.$active ? props.theme.primaryBlue : props.theme.primaryBlack)};
  text-align: left;
  outline: none;
  background: ${({ $active, theme }) => ($active ? theme.applyOpacity(theme.secondaryWhite, 0.59) : 'transparent')};

  ${ChevronIcon} {
    transform: ${(props) => (props.$active ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.primaryBlue};
  }
  &:focus {
    color: ${({ theme }) => theme.primaryBlue};
    background: ${({ theme }) => theme.applyOpacity(theme.secondaryWhite, 0.59)};
  }
  &:hover,
  &:focus {
    ${VideoIcon} {
      fill: ${({ theme }) => theme.primaryBlue};
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 3.5rem 6rem;
  }
`

export const CollapseTriggerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    justify-content: normal;
    width: auto;
  }
`

export const CollapseTriggerIcon = styled.div`
  margin-left: 1.5rem;
  display: flex;
`

interface DrawerProps {
  $active?: boolean
}
export const Drawer = styled.div<DrawerProps>`
  transition: 0.2s ease-in-out opacity;
  height: ${(props) => (props.$active ? '100%' : '0')};
  overflow: ${(props) => (props.$active ? 'auto' : 'hidden')};
  opacity: ${(props) => (props.$active ? '1' : '0')};
  visibility: ${(props) => (props.$active ? 'visible' : 'hidden')};
  background: ${({ theme }) => theme.applyOpacity(theme.secondaryWhite, 0.59)};
`

export const CollapseContainer = styled.div`
  padding: 3.3rem 1rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 0 6rem 3.3rem 6rem;
  }
`

export const CollapseText = styled.div`
  p {
    color: ${({ theme }) => theme.secondaryBlack};
    font-family: ${({ theme }) => theme.fontName};
    font-size: 1.8rem;
  }
  p:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }
  b {
    font-weight: 800;
  }
  i {
    font-style: italic;
    font-weight: 500;
  }
  ul {
    margin-left: 3rem;
    margin-bottom: 4rem;
    li {
      position: relative;
      > p {
        margin-bottom: 2rem;
      }
    }
    li::before {
      position: absolute;
      content: '•';
      color: ${({ theme }) => theme.primaryBlue};
      font-weight: bold;
      font-size: 3rem;
      display: inline-block;
      width: 15px;
      top: 0;
      margin-left: -1em;
    }
  }
  a {
    transition: 0.5s color ease;
    color: ${({ theme }) => theme.primaryGreen};
    font-family: ${({ theme }) => theme.fontName};
    font-weight: 700;
    text-decoration: none;
    &:hover,
    &:focus {
      color: ${({ theme }) => theme.secondaryGreen};
    }
  }
`

interface IframeWrapperProps {
  $embedWidth: string
}
export const IframeWrapper = styled.div<IframeWrapperProps>`
  position: relative;
  margin-top: 2.8rem;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 2.6rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding-bottom: ${(props) => (props.$embedWidth === 'full' ? '56.25%' : '40%')};
  }
`

interface IframeProps {
  $embedWidth: string
}
export const Iframe = styled.iframe<IframeProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    left: ${(props) => (props.$embedWidth === 'full' ? '0' : '20%;')};
    width: ${(props) => (props.$embedWidth === 'full' ? '100%' : '60%')};
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 3rem;
`

export const StyledLink = styled(Link)`
  transition: 0.5s ease background;
  display: inline-block;
  padding: 1.6rem 2rem;
  color: ${({ theme }) => theme.primaryWhite};
  background: ${({ theme }) => theme.primaryGreen};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 1.6rem;
  font-weight: 700;
  border-radius: 0.5rem;
  text-decoration: none;
  box-shadow:
    0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.secondaryGreen};
  }
`

export const Back = styled.a`
  transition: 0.5s ease color;
  display: inline-flex;
  align-items: center;
  margin-top: 2rem;
  color: ${({ theme }) => theme.primaryBlue};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 2rem;
  font-weight: 700;
  text-decoration: none;
  svg {
    transition: 0.5s ease fill;
    fill: ${({ theme }) => theme.primaryBlue};
  }
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.primaryBlack};
    svg {
      fill: ${({ theme }) => theme.primaryBlack};
    }
  }
`

export const BackText = styled.span`
  margin-right: 1rem;
`

export const TopTitle = styled.div`
  margin-bottom: 4.5rem;
`
