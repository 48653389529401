import React from 'react'
import { StyledTitle } from './linkText.styles'

interface LinkTextProps {
  tag: any
  text: string
}

const LinkText = ({ tag, text }: LinkTextProps) => {
  return <StyledTitle as={tag}>{text}</StyledTitle>
}

export default LinkText
