import React from 'react'
import { StyledTitle } from './titleThree.styles'

interface TitleThreeProps {
  tag: any
  text?: string
}

const TitleThree = ({ tag, text }: TitleThreeProps) => {
  return <StyledTitle as={tag}>{text}</StyledTitle>
}

export default TitleThree
