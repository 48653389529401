import styled from 'styled-components'

export const Component = styled.section`
  margin-bottom: 5.8rem;
  color: ${({ theme }) => theme.secondaryBlack};
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 19.7rem;
  }
`

export const Container = styled.div`
  max-width: ${({ theme }) => theme.widthContainer};
  margin: 0 auto;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 0 ${({ theme }) => theme.widthDocumentGutter};
  }
`

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-bottom: 2.5rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    align-items: center;
    margin-bottom: 0;
    padding: 2.3rem 10.4rem;
  }
`

export const HeaderOne = styled.div`
  width: 100%;
  margin-bottom: 3rem;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 60%;
    margin-bottom: 0;
    text-align: left;
  }
`

export const HeaderTwo = styled.div`
  display: flex;
  justify-content: center;
  width: 30%;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: flex;
    justify-content: center;
    padding: 0 1.5rem;
    width: 20%;
  }
`

export const HeaderThree = styled.div`
  display: flex;
  justify-content: center;
  width: 30%;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 20%;
  }
`

export const Title = styled.p`
  font-family: ${({ theme }) => theme.fontName};
  font-weight: 800;
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: 1.8rem;
  }
`

export const TitleBreak = styled.span`
  display: block;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: inline-block;
  }
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 2.3rem 1.8rem;
  &:nth-of-type(even) {
    background: ${({ theme }) => theme.applyOpacity(theme.secondaryWhite, 0.59)};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 0 10.4rem;
  }
`

export const One = styled.div`
  padding: 1rem 0;
  width: 40%;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 2.3rem 0;
    width: 60%;
    border-right: 1px solid ${({ theme }) => theme.secondaryWhite};
  }
`

export const Two = styled.div`
  padding: 1rem 0;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 2.3rem 0;
    width: 20%;
    border-right: 1px solid ${({ theme }) => theme.secondaryWhite};
  }
`

export const IconHighlight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  height: 3rem;
  width: 3rem;
  background: ${({ theme }) => theme.primaryGreen};
  box-shadow:
    0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
`

export const Three = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 20%;
  }
`

export const IconAlert = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  height: 3rem;
  width: 3rem;
  background: ${({ theme }) => theme.primaryRed};
  box-shadow:
    0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
`
