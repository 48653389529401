export enum DataFeedCardType {
  ICON = 'ICON',
  CONTEST = 'CONTEST',
  MARKET = 'MARKET',
}

export interface Card {
  type: DataFeedCardType
  contestName: string
  startTime: number
  league: string
  homeTeam: string
  awayTeam: string
  contestDate: string
  sellPrice: string
  buyPrice: string
  marketType: string
  marketTypeSymbol: string
}

export interface BackendResponse<T> {
  data: T
  error: string
}

export interface IBook {
  [outcomeId: string]: {
    askLevels: IBookPriceLevel
    bidLevels: IBookPriceLevel
  }
}
export interface IBookPriceLevel {
  [price: number]: number
}

export interface IPriceLevel {
  price: number
  quantity: number
}
export interface IMarketData {
  outcomeId: string
  bidLevels: IPriceLevel[]
  askLevels: IPriceLevel[]
}

export type DataFeedStructure = {
  [key: string]: Card[]
  NFL: Card[]
  NBA: Card[]
  WNBA: Card[]
  MLB: Card[]
  NCAAF: Card[]
  NCAAB: Card[]
  NHL: Card[]
}
