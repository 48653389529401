import styled, { keyframes } from 'styled-components'

const pulse = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
`

export const SkeletonContainer = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  height: 195px;
  background-color: transparent;
`

export const SkeletonBox = styled.div`
  flex-shrink: 0;
  height: 115px;
  background-color: #d1d5db;
  border-radius: 0.375rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
`

export const SkeletonBoxSmall = styled(SkeletonBox)`
  width: 115px;
`

export const SkeletonBoxMedium = styled(SkeletonBox)`
  width: 300px;
`

export const SkeletonBoxLarge = styled(SkeletonBox)`
  width: 200px;
`
