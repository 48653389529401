import styled from 'styled-components'

interface BadgeProps {
  $inverse: boolean
}

export const Link = styled.a<BadgeProps>`
  display: block;
  background: ${(props) => (props.$inverse ? 'transparent' : '#232C33')};
  height: 6.4rem;
  border-radius: 5px;
`

export const Badge = styled.div<BadgeProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 6.4rem;
  border-radius: 5px;
  color: ${(props) => (props.$inverse ? '#fff' : props.theme.primaryGray)};
  border: 2px solid ${(props) => (props.$inverse ? '#dde2e5' : props.theme.primaryGray)};
  text-align: center;
  background: transparent;
  width: 20rem;
`

export const BadgeText = styled.p`
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fontName};
  font-weight: 800;
  line-height: 1.2;
`
