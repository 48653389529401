import styled from 'styled-components'

export const StyledTitle = styled.span`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fontName};
  font-size: ${({ theme }) => theme.fontSizes.h5};
  font-weight: 700;
  line-height: 1.1;
  svg {
    margin-left: 1.3rem;
  }
`
