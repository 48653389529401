import React from 'react'
import { Ol, Ul, Paragraph, H2, H3, H4, A } from './articleBody.styles'
import { RichTextReplacements, parseBuilderRichText } from '../../helpers/builderRichTextParser'
import { BuilderCustomComponentProps } from '../../types/builderio-ids'

interface ArticleBodyProps extends BuilderCustomComponentProps {
  body: string
  isDarkMode?: boolean
}

const getReplacements = (isDarkMode: boolean): RichTextReplacements => [
  { nodeName: 'strong', Component: 'b' },
  { nodeName: 'h2', Component: H2 },
  { nodeName: 'h3', Component: H3 },
  { nodeName: 'h4', Component: H4 },
  { nodeName: 'p', Component: Paragraph },
  { nodeName: 'ol', Component: Ol },
  { nodeName: 'ul', Component: Ul },
  { nodeName: 'a', Component: (props) => <A {...props} isDarkMode={isDarkMode} /> },
]

// This component will be used only on article pages generated in builder,
// for hardcoded content use ArticleBody component instead
const BlogPostBody = ({ body, isDarkMode = false, attributes }: ArticleBodyProps): JSX.Element => (
  <div {...attributes}>{parseBuilderRichText(body, getReplacements(isDarkMode))}</div>
)

export default BlogPostBody
