import { Builder } from '@builder.io/react'
import BlogPostBody from './blogPostBody'

Builder.registerComponent(BlogPostBody, {
  name: 'ArticleBody',
  inputs: [
    {
      name: 'isDarkMode',
      helperText: 'Use dark mode for this component (will affect the green color for links only)',
      type: 'boolean',
      defaultValue: false,
    },
    { name: 'body', friendlyName: 'text', type: 'richText', defaultValue: '<p>Article body text here...</p>' },
  ],
  noWrap: true,
})
