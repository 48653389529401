import React, { useState } from 'react'
import betConverters from '../../helpers'
import {
  Caption,
  Component,
  ControlOne,
  ControlTwo,
  Form,
  Input,
  InputOffset,
  InputWithSymbol,
  Label,
  Result,
  ResultVariable,
  Title,
  Symbol,
} from './calculatorOdds.styles'

interface CalculatorOddsProps {
  title: string
  oddsCalculatorDefaultAmericanOdds: number
  oddsCalculatorDefaultImpliedProbability: number
}

const CalculatorOdds = ({
  title,
  oddsCalculatorDefaultAmericanOdds,
  oddsCalculatorDefaultImpliedProbability,
}: CalculatorOddsProps) => {
  const [americanOddsError, setAmericanOddsError] = useState(false)
  const [impliedProbabilityError, setImpliedProbabilityError] = useState(false)

  const [americanOdds, setAmericanOdds] = useState(oddsCalculatorDefaultAmericanOdds)
  const [impliedProbability, setImpliedProbability] = useState<number | undefined>(
    oddsCalculatorDefaultImpliedProbability,
  )

  function handleAmericanOdds(event: React.FormEvent<HTMLInputElement>) {
    const value = parseFloat(event.currentTarget.value)
    if ((value > 99 && value <= 999900) || (value <= -100 && value >= -999900)) {
      setAmericanOddsError(false)
      setImpliedProbability(betConverters.americanOddsToImpliedProbability(value))
      setImpliedProbabilityError(false)
    } else {
      setAmericanOddsError(true)
      setImpliedProbability(0)
    }

    setAmericanOdds(betConverters.round(value, 0))
  }

  function handleImpliedProbability(event: React.FormEvent<HTMLInputElement>) {
    const trimmed = betConverters.trim(parseFloat(event.currentTarget.value), 5)

    if (trimmed >= 1 && trimmed <= 100) {
      setImpliedProbabilityError(false)
      setAmericanOdds(betConverters.impliedProbabilityToAmericanOdds(trimmed))
      setAmericanOddsError(false)
    } else {
      setImpliedProbabilityError(true)
      setAmericanOdds(0)
    }

    setImpliedProbability(betConverters.round(trimmed, 2))
  }

  return (
    <Component>
      <Title>{title}</Title>
      <Form>
        <ControlOne>
          <Label htmlFor="american-odds">American Odds</Label>
          <InputWithSymbol>
            <Input type="number" name="american-odds" value={americanOdds || ''} onChange={handleAmericanOdds}></Input>
          </InputWithSymbol>
          <Caption error={americanOddsError} id="american-odds-helper">
            Must be a number greater than +100 or less than -100
          </Caption>
        </ControlOne>
        <ControlTwo>
          <Label htmlFor="implied-probability">Implied Probability</Label>
          <InputWithSymbol>
            <InputOffset
              type="number"
              name="implied-probability"
              value={impliedProbability || ''}
              onChange={handleImpliedProbability}
            ></InputOffset>
            <Symbol>%</Symbol>
          </InputWithSymbol>
          <Caption error={impliedProbabilityError} id="implied-probability-helper">
            Must be a number between 1 & 100
          </Caption>
        </ControlTwo>
      </Form>
      <Result>
          See, it’s simpler.<br />
          Your team has a <ResultVariable id="odds-converter-result">{impliedProbability || `--`}% </ResultVariable> 
          chance of winning and it would cost 
          <ResultVariable id="odds-converter-result"> ${impliedProbability || `--`} </ResultVariable>
          to buy one contract on Sporttrade.
      </Result>
    </Component>
  )
}

export default CalculatorOdds
