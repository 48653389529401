import React, { FormEvent, useState } from 'react'
import PropTypes from 'prop-types'

import { getRecaptchaToken, submitForm } from '../../helpers/api'
import { Button, Component, Control, Input, Label, Option, Select } from './signUp.styles'

interface SignUpProps {
  inverse: boolean
  context: string
}

const SignUp = ({ inverse, context }: SignUpProps) => {
  const [firstName, setFirstName] = useState(''),
    [lastName, setLastName] = useState(''),
    [state, setState] = useState(''),
    [email, setEmail] = useState('')

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      const formData = {
        firstName,
        lastName,
        state,
        email,
      }
      if (typeof window !== undefined) {
        const token = await getRecaptchaToken(process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY, window.grecaptcha)
        const response = await submitForm(formData, token)

        if (response.error) {
          console.log('error')
        } else {
          window.location.href = '/thank-you/'
        }
      }
    } catch (error) {
      console.log('error')
    }
  }
  return (
    <Component>
      <form onSubmit={handleSubmit}>
        <Control>
          <Label htmlFor={`m_first_name_${context}`}>First Name</Label>
          <Input
            $inverse={inverse}
            type="text"
            id={`m_first_name_${context}`}
            name="first_name"
            aria-required="true"
            placeholder="First name"
            required
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
          />
        </Control>
        <Control>
          <Label htmlFor={`m_last_name_${context}`}>Last Name</Label>
          <Input
            $inverse={inverse}
            type="text"
            id={`m_last_name_${context}`}
            name="last_name"
            aria-required="true"
            placeholder="Last name"
            required
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
          />
        </Control>
        <Control>
          <Label htmlFor={`m_state_${context}`}>State</Label>
          <Select
            $inverse={inverse}
            typeof="state"
            id={`m_state_${context}`}
            name="state"
            aria-required="true"
            required
            value={state}
            onChange={(event) => setState(event.target.value)}
          >
            <Option value="" hidden disabled>
              State
            </Option>
            <Option value="Alabama">AL</Option>
            <Option value="Alaska">AK</Option>
            <Option value="Arizona">AZ</Option>
            <Option value="Arkansas">AR</Option>
            <Option value="California">CA</Option>
            <Option value="Colorado">CO</Option>
            <Option value="Connecticut">CT</Option>
            <Option value="Delaware">DE</Option>
            <Option value="Washington D.C.">DC</Option>
            <Option value="Florida">FL</Option>
            <Option value="Georgia">GA</Option>
            <Option value="Hawaii">HI</Option>
            <Option value="Idaho">ID</Option>
            <Option value="Illinois">IL</Option>
            <Option value="Indiana">IN</Option>
            <Option value="Iowa">IA</Option>
            <Option value="Kansas">KS</Option>
            <Option value="Kentucky">KY</Option>
            <Option value="Louisiana">LA</Option>
            <Option value="Maine">ME</Option>
            <Option value="Maryland">MD</Option>
            <Option value="Massachusetts">MA</Option>
            <Option value="Michigan">MI</Option>
            <Option value="Minnesota">MN</Option>
            <Option value="Mississippi">MS</Option>
            <Option value="Missouri">MO</Option>
            <Option value="Montana">MT</Option>
            <Option value="Nebraska">NE</Option>
            <Option value="Nevada">NV</Option>
            <Option value="New Hampshire">NH</Option>
            <Option value="New Jersey">NJ</Option>
            <Option value="New Mexico">NM</Option>
            <Option value="New York">NY</Option>
            <Option value="North Carolina">NC</Option>
            <Option value="North Dakota">ND</Option>
            <Option value="Ohio">OH</Option>
            <Option value="Oklahoma">OK</Option>
            <Option value="Oregon">OR</Option>
            <Option value="Pennsylvania">PA</Option>
            <Option value="Rhode Island">RI</Option>
            <Option value="South Carolina">SC</Option>
            <Option value="South Dakota">SD</Option>
            <Option value="Tennessee">TN</Option>
            <Option value="Texas">TX</Option>
            <Option value="Utah">UT</Option>
            <Option value="Vermont">VT</Option>
            <Option value="Virginia">VA</Option>
            <Option value="Washington">WA</Option>
            <Option value="West Virginia">WV</Option>
            <Option value="Wisconsin">WI</Option>
            <Option value="Wyoming (not DE)">WY</Option>
          </Select>
        </Control>
        <Control>
          <Label htmlFor={`m_email_${context}`}>Email</Label>
          <Input
            $inverse={inverse}
            type="email"
            id={`m_email_${context}`}
            name="email"
            aria-required="true"
            placeholder="Email address"
            required
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </Control>
        <Control>
          <Button type="submit" name="submit">
            Sign Up
          </Button>
        </Control>
      </form>
    </Component>
  )
}

SignUp.propTypes = {
  inverse: PropTypes.bool.isRequired,
  context: PropTypes.string.isRequired,
}

export default SignUp
