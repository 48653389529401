import { Builder } from '@builder.io/react'
import Matrix from './matrix'

Builder.registerComponent(Matrix, {
  name: 'Comparison Chart',
  inputs: [
    {
      name: 'logoUrl',
      type: 'url',
      defaultValue:
        'https://cdn.builder.io/api/v1/image/assets%2Ff127073fccdc44a590bf4362d8bb100b%2Ff184961c18e6481bb102683fbb757759',
    },
    {
      name: 'features',
      friendlyName: 'Features',
      type: 'list',
      required: true,
      defaultValue: [
        {
          feature: {
            title: 'Feature 1',
          },
        },
      ],
      subFields: [
        {
          name: 'feature',
          type: 'object',
          subFields: [
            {
              name: 'title',
              type: 'string',
              required: true,
            },
          ],
        },
      ],
    },
  ],
})
