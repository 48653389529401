import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useEffect, useState } from 'react'
import BadgeAndroid from '../BadgeAndroid/badgeAndroid'
import BadgeApple from '../BadgeApple/badgeApple'
import DataFeed from '../DataFeed/dataFeed'
import LinkText from '../LinkText/linkText'
import NavLink from '../NavLink/navLink'
import Screen from '../Screen/screen'
import SignUp from '../SignUp/signUp'
import Social from '../Social/social'
import TitleThree from '../TitleThree/titleThree'
import TitleTwo from '../TitleTwo/titleTwo'
import {
  Badges,
  Base,
  BaseLockupList,
  BaseLockupListItem,
  BaseLockupListItemExternal,
  BaseLockupListItemLink,
  Body,
  BodyText,
  BodyTitle,
  Component,
  Container,
  Copyright,
  DownloadContainer,
  DropUp,
  DropUpIcon,
  DropUpText,
  DropUpViewport,
  DropUpViewportLink,
  DropUpViewportListItem,
  Form,
  Group,
  LegalList,
  LegalListItem,
  Ribbon,
  RibbonNavigation,
  RibbonNavigationList,
  RibbonNavigationListItem,
  SectionOne,
  SocialContainer,
  TheCopyright,
} from './footer.styles'
import { BuilderIoContentResponse, FooterModel, GlobalModel } from '../../types/builderio-models'
import { parseBuilderRichText } from '../../helpers/builderRichTextParser'
import { BuilderCustomComponentProps } from '../../types/builderio-ids'

interface FooterProps extends BuilderCustomComponentProps {
  headingTag?: string
  heroArtwork?: string
  showForm: boolean
  cardIcons?: Pick<GlobalModel, 'cardIcons'>['cardIcons']
}

const Footer = ({ headingTag, showForm, cardIcons, heroArtwork, attributes }: FooterProps) => {
  const data: {
    allBuilderModels: {
      oneGlobal: {
        data: Pick<GlobalModel, 'appleAppDownloadLink' | 'androidAppDownloadLink'>
      }
      oneFooter: {
        content: BuilderIoContentResponse<FooterModel, 'data'>
      }
    }
  } = useStaticQuery(graphql`
    query FooterQuery {
      allBuilderModels {
        oneGlobal {
          data {
            appleAppDownloadLink
            androidAppDownloadLink
          }
        }
        oneFooter(options: { enrich: true, cachebust: true }) {
          content
        }
      }
    }
  `)

  const year = new Date().getFullYear()
  const [active, setActive] = useState<boolean>()

  const [width, setWidth] = useState(0)
  const breakpoint = 600

  useEffect(() => {
    setWidth(window.innerWidth)
    window.addEventListener('resize', () => setWidth(window.innerWidth))
    return () => {
      window.removeEventListener('resize', () => setWidth(window.innerWidth))
    }
  }, [])

  const {
    heroArtwork: footerHeroArtwork,
    disclaimerText,
    legalIcons,
    formText,
    title,
    copyrightText,
    socialIcons,
    socialIconsTitle,
    utilityLinks,
    seoLandingPageLinks,
  } = data.allBuilderModels.oneFooter.content.data
  const { appleAppDownloadLink, androidAppDownloadLink } = data.allBuilderModels.oneGlobal.data

  const toggle = () => {
    if (active) {
      setActive(false)
    } else {
      setActive(true)
    }
  }
  return (
    <Component {...attributes} id="sign-up" $showForm={showForm}>
      <DataFeed cardIcons={cardIcons} />
      <Container>
        {showForm && (
          <SectionOne>
            <DownloadContainer>
              {(heroArtwork || footerHeroArtwork) && width > breakpoint && (
                <Screen
                  artwork={{
                    url: heroArtwork || footerHeroArtwork,
                    description: 'Sporttrade App Download Now',
                  }}
                />
              )}
              <Group>
                <BodyTitle>
                  <TitleTwo tag="p" text="Download Now" />
                </BodyTitle>
                <BodyText>
                  <LinkText
                    tag="p"
                    text="Available in the Apple App Store and on Google Play. Sporttrade is coming to additional states throughout 2024. Must be 21 or older."
                  />
                </BodyText>
                <Badges>
                  <BadgeApple target={appleAppDownloadLink} id="footer-badge-link" inverse={true} />
                  <BadgeAndroid target={androidAppDownloadLink} id="footer-badge-link" inverse={true} />
                </Badges>
              </Group>
            </DownloadContainer>
            <Form>
              <Body>
                <BodyTitle>
                  <TitleThree tag={headingTag} text={title} />
                </BodyTitle>
                <BodyText>
                  <LinkText tag="p" text={formText} />
                </BodyText>
              </Body>
              <SignUp inverse={true} context="footer" />
            </Form>
          </SectionOne>
        )}
        <Ribbon>
          <SocialContainer>
            <Social socialIconsTitle={socialIconsTitle} socialIcons={socialIcons} />
          </SocialContainer>
          <RibbonNavigation>
            <RibbonNavigationList>
              <RibbonNavigationListItem>
                <NavLink target="/about/" text="About" />
              </RibbonNavigationListItem>
              <RibbonNavigationListItem>
                <NavLink target="/careers/" text="Careers" />
              </RibbonNavigationListItem>
              <RibbonNavigationListItem>
                <NavLink target="/contact/" text="Contact" />
              </RibbonNavigationListItem>
            </RibbonNavigationList>
          </RibbonNavigation>
        </Ribbon>
        <Base>
          <Copyright>{parseBuilderRichText(disclaimerText)}</Copyright>
          <LegalList>
            {legalIcons.map(({ legalIcon }) => {
              const item = legalIcon.value.data
              return (
                <LegalListItem key={item.title}>
                  {item.url ? (
                    <a aria-label={item.title} href={item.url}>
                      <img height={40} src={item.image.file} />
                    </a>
                  ) : (
                    <img height={40} src={item.image.file} />
                  )}
                </LegalListItem>
              )
            })}
          </LegalList>
          <BaseLockupList>
            <BaseLockupListItem>
              <DropUp onClick={toggle}>
                <DropUpViewport active={active}>
                  <ul>
                    {seoLandingPageLinks.map(({ seoLandingPageLink }, i: number) => {
                      const item = seoLandingPageLink.value.data
                      return (
                        <DropUpViewportListItem key={i}>
                          <DropUpViewportLink to={`/${item.slug}/`}>{item.seoLinkName}</DropUpViewportLink>
                        </DropUpViewportListItem>
                      )
                    })}
                  </ul>
                </DropUpViewport>
                <DropUpText>Bet on Sports</DropUpText>
                <DropUpIcon active={active}>
                  <svg width="12" height="7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.941 2.058L9.91 6.03a.747.747 0 001.06 0 .753.753 0 000-1.062L6.471.468A.749.749 0 005.438.446L.911 4.964a.75.75 0 00-.219.531.75.75 0 001.278.531l3.971-3.968z" />
                  </svg>
                </DropUpIcon>
              </DropUp>
            </BaseLockupListItem>
            {utilityLinks.map(({ utilityLink }, i: number) => {
              const item = utilityLink.value.data
              return (
                <BaseLockupListItem key={i}>
                  <BaseLockupListItemLink to={`/${item.slug}/`} rel="nofollow">
                    {item.shortTitle}
                  </BaseLockupListItemLink>
                </BaseLockupListItem>
              )
            })}
            <BaseLockupListItem>
              <BaseLockupListItemExternal
                href="https://legal.getsporttrade.com/house-rules"
                target="_blank"
                rel="nofollow"
              >
                House Rules
              </BaseLockupListItemExternal>
            </BaseLockupListItem>
          </BaseLockupList>
          <TheCopyright>{`© ${year} ${copyrightText}`}</TheCopyright>
        </Base>
      </Container>
    </Component>
  )
}

export default Footer
