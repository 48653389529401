import React from 'react'
import { Arrow, Chevron, Component, TextFull, TextTruncated, Text } from './notification.styles'

interface NotificationProps {
  notificationType: string
  attrTarget: string
  attrRel: string
  textFull: string
  textTruncated: string
  viewport: string
  target: string
  alertColor: string
  successColor: string
}

function getWidthMobile(width: string) {
  if (width === 'none') {
    return '100%'
  } else if (width === 'medium') {
    return '30%'
  } else if (width === 'all') {
    return '70%'
  }
}

const Notification = ({
  notificationType,
  attrTarget,
  attrRel,
  textFull,
  textTruncated,
  viewport,
  target,
  alertColor,
  successColor,
}: NotificationProps) => {
  return (
    <Component
      href={target}
      target={attrTarget}
      rel={attrRel}
      $viewport={viewport}
      $widthMobile={getWidthMobile(viewport)}
      $notificationType={notificationType}
      $alertColor={alertColor}
      $successColor={successColor}
    >
      <div>
        <Text>
          <TextFull>{textFull}</TextFull>
        </Text>

        <Text>
          <TextTruncated>{textTruncated}</TextTruncated>
        </Text>

        <Arrow>
          <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.998.87a.868.868 0 00-.007 1.222l4.038 4.045H1.662A.86.86 0 00.806 7a.86.86 0 00.856.863h15.36l-4.037 4.044a.874.874 0 00.006 1.222.86.86 0 001.215-.006l5.472-5.512a.969.969 0 00.18-.272.865.865 0 00-.18-.936L14.206.89a.846.846 0 00-1.208-.02z"
              fill="#fff"
            />
          </svg>
        </Arrow>

        <Chevron>
          <svg width="9" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.536 1.464L7.07 5 3.536 8.536" stroke="#fff" strokeWidth="2" />
          </svg>
        </Chevron>
      </div>
    </Component>
  )
}

export default Notification
