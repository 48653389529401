import { Builder } from '@builder.io/react'
import Form from './form'

Builder.registerComponent(Form, {
  name: 'Lead Capture Form',
  inputs: [
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Sign up for our newsletter',
    },
    {
      name: 'text',
      type: 'string',
      defaultValue: 'Get the latest news and updates',
    },
  ],
  noWrap: true,
})
