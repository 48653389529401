import styled from 'styled-components'

interface FormProps {
  size?: number
}

export const FormContainer = styled.div`
  padding: 4.2rem 3rem 4.7rem;
  border-radius: 1.5rem;
  background: ${({ theme }) => theme.primaryWhite};
  box-shadow:
    0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 0 15%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin: 0;
    padding: 4.7rem 5.1rem 6.1rem;
  }
`

export const FormTitle = styled.h2`
  text-align: center;
  margin-bottom: 0.9rem;
  color: ${({ theme }) => theme.primaryBlack};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 2rem;
  font-weight: 700;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    text-align: left;
    margin-bottom: 1.1rem;
    font-size: 2.4rem;
  }
`

export const FormText = styled.p`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 50rem;
  margin-bottom: 2.6rem;
  color: ${({ theme }) => theme.primaryBlack};
  font-family: ${({ theme }) => theme.fontName};
  font-weight: 400;
  font-size: 1.6rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    text-align: left;
    max-width: 100%;
    margin-bottom: 3.5rem;
    font-size: 1.8rem;
  }
`

export const Control = styled.div`
  margin-bottom: 2.3rem;
`

export const Label = styled.label`
  position: absolute;
  left: -999em;
  right: auto;
`

export const Input = styled.input`
  display: block;
  width: 100%;
  margin: 0;
  padding: 1.6rem 2rem;
  border-radius: 0.8rem;
  font-size: 1.8rem;
  font-family: ${({ theme }) => theme.fontName};
  font-weight: 700;
  line-height: 1.3;
  border: 1px solid ${({ theme }) => theme.primaryGray};
  color: ${({ theme }) => theme.primaryGray};
  background: ${({ theme }) => theme.primaryWhite};
  &:hover,
  &:focus {
    border-color: #888;
  }
  &::placeholder {
    color: ${({ theme }) => theme.primaryGray};
  }
`

export const Textarea = styled.textarea`
  width: 100%;
  padding: 1.6rem 2rem;
  border-radius: 0.8rem;
  resize: none;
  background: ${({ theme }) => theme.primaryWhite};
  border: 1px solid ${({ theme }) => theme.primaryGray};
  color: ${({ theme }) => theme.primaryGray};
  font-size: 1.8rem;
  font-family: ${({ theme }) => theme.fontName};
  font-weight: 700;
  line-height: 1.3;
  &::placeholder {
    color: ${({ theme }) => theme.primaryGray};
  }
`

export const Select = styled.select`
  display: block;
  font-size: 1.8rem;
  font-family: ${({ theme }) => theme.fontName};
  font-weight: 700;
  line-height: 1.3;
  padding: 1.6rem 2rem;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border-radius: 0.8rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='11' viewBox='0 0 13 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.366 10.5a1 1 0 01-1.732 0L.871 2.25a1 1 0 01.866-1.5h9.526a1 1 0 01.866 1.5L7.366 10.5z' fill='%23868F96'/%3E%3C/svg%3E");
  background-repeat: no-repeat, repeat;
  background-position:
    right 2rem top 50%,
    0 0;
  background-size:
    0.65em auto,
    100%;
  border: 1px solid ${({ theme }) => theme.primaryGray};
  color: ${({ theme }) => theme.primaryGray};
  background-color: ${({ theme }) => theme.primaryWhite};
  &:focus,
  &:hover {
    cursor: pointer;
    border-color: #888;
  }
  &:::-ms-expand {
    display: none;
  }
`

export const Option = styled.option`
  font-weight: normal;
`

export const Button = styled.button`
  transition: 0.5s ease background;
  width: 100%;
  display: inline-block;
  padding: 1.6rem 2rem;
  color: ${({ theme }) => theme.primaryWhite};
  background: ${({ theme }) => theme.primaryGreen};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 1.6rem;
  font-weight: 700;
  border-radius: 0.5rem;
  text-decoration: none;
  box-shadow:
    0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.secondaryGreen};
  }
`
