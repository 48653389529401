import React from 'react'
import {
  RichTextParagraph,
  RichTextOl,
  RichTextUl,
  RichTextHeadingTwo,
  RichTextHyperlink,
  RichTextHeadingOne,
  RichTextHeadingThree,
  RichTextHeadingFour,
} from './genericRichText.styles'
import { RichTextReplacements, parseBuilderRichText } from '../../helpers/builderRichTextParser'

interface GenericRichTextProps {
  text: string
}
const replacements: RichTextReplacements = [
  { nodeName: 'p', Component: RichTextParagraph },
  { nodeName: 'ol', Component: RichTextOl },
  { nodeName: 'ul', Component: RichTextUl },
  { nodeName: 'h1', Component: RichTextHeadingOne },
  { nodeName: 'h2', Component: RichTextHeadingTwo },
  { nodeName: 'h3', Component: RichTextHeadingThree },
  { nodeName: 'h4', Component: RichTextHeadingFour },
  { nodeName: 'a', Component: RichTextHyperlink },
]

const GenericRichText = ({ text }: GenericRichTextProps) => {
  return <>{parseBuilderRichText(text, replacements)}</>
}

export default GenericRichText
