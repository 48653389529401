import styled from 'styled-components'

interface CalculatorOddsStyledProps {
  error: boolean
}

export const Component = styled.aside`
  padding: 4rem;
  background: ${({ theme }) => theme.primaryWhite};
  box-shadow:
    0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  border: 1px solid #eff1ed;
  border-radius: 1.5rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 5.5rem 8rem;
  }
`

export const Title = styled.p`
  margin-bottom: 3.2rem;
  color: ${({ theme }) => theme.primaryBlack};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 2.6rem;
  font-weight: 800;
  line-height: 1.1;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    text-align: left;
  }
`

export const Form = styled.form`
  display: flex;
  margin-left: -2rem;
  margin-bottom: 3.4rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    align-items: end;
  }
`

export const ControlOne = styled.div`
  width: 50%;
  padding-left: 2rem;
`

export const ControlTwo = styled.div`
  width: 50%;
  padding-left: 2rem;
`

export const Caption = styled.p<CalculatorOddsStyledProps>`
  color: ${(props) => (props.error ? '#CF2D2D' : '#868f96')};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 1.2em;
`

export const Label = styled.label`
  display: block;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.secondaryBlack};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 2rem;
  font-weight: 800;
`

export const Input = styled.input`
  display: block;
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.8rem;
  font-size: 1.8rem;
  font-family: ${({ theme }) => theme.fontName};
  font-weight: 700;
  line-height: 1.3;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.primaryGray};
  color: ${({ theme }) => theme.primaryBlack};
  background: ${({ theme }) => theme.primaryWhite};
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:hover,
  &:focus {
    border-color: #888;
  }
  &::placeholder {
    color: ${({ theme }) => theme.primaryGray};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 1.6rem 2rem;
  }
`

export const InputOffset = styled.input`
  display: block;
  width: 100%;
  padding: 0.5rem;
  border-top-left-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 1.8rem;
  font-family: ${({ theme }) => theme.fontName};
  font-weight: 700;
  line-height: 1.3;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.primaryGray};
  color: ${({ theme }) => theme.primaryGreen};
  background: ${({ theme }) => theme.primaryWhite};
  -webkit-appearance: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:hover,
  &:focus {
    border-color: #888;
  }
  &::placeholder {
    color: ${({ theme }) => theme.primaryGray};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 1.6rem 2rem;
  }
`

export const InputWithSymbol = styled.div`
  display: flex;
  margin-bottom: 1.8rem;
`

export const Symbol = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  color: #000;
  font-family: ${({ theme }) => theme.fontName};
  font-size: 1.8rem;
  font-weight: 800;
  background: #c4c4c4;
  line-height: 0;
`

export const Result = styled.p`
  display: block;
  color: ${({ theme }) => theme.secondaryBlack};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 2rem;
  font-weight: 800;
`

export const ResultVariable = styled.span`
  color: ${({ theme }) => theme.primaryGreen};
`
