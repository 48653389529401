import { Builder } from '@builder.io/react'
import Calculator from './calculator'
import { AssetID } from '../../types/builderio-ids'

Builder.registerComponent(Calculator, {
  name: 'Calculator',
  inputs: [
    {
      name: 'profitCalculatorSporttradeLogo',
      friendlyName: 'Sporttrade Logo',
      type: 'reference',
      model: 'asset' satisfies AssetID,
      required: true,
    },
    {
      name: 'profitCalculatorTitleOne',
      friendlyName: 'Bottom First Title',
      type: 'string',
      defaultValue: 'More transparency = better pricing',
      required: true,
    },
    {
      name: 'profitCalculatorTitleTwo',
      friendlyName: 'Bottom Second Title',
      type: 'string',
      defaultValue: 'Commission structure = better payouts',
      required: true,
    },
    {
      name: 'profitCalculatorOptionsIcon',
      type: 'string',
      enum: ['football', 'basketball', 'baseball', 'hockey'],
      required: true,
    },
    {
      name: 'profitCalculatorOptionOneLabel',
      friendlyName: 'Option 1 Label',
      type: 'string',
      required: true,
    },
    {
      name: 'profitCalculatorOptionOneBuyPrice',
      friendlyName: 'Option 1 Buy Price',
      type: 'number',
      required: true,
    },
    {
      name: 'profitCalculatorOptionOneAmericanOdds',
      friendlyName: 'Option 1 American Odds Profit',
      type: 'number',
      required: true,
    },
    {
      name: 'profitCalculatorOptionOneSportsbook',
      friendlyName: 'Option 1 Sportsbook Profit',
      type: 'string',
      required: true,
    },
    {
      name: 'profitCalculatorOptionTwoLabel',
      friendlyName: 'Option 2 Label',
      type: 'string',
      required: true,
    },
    {
      name: 'profitCalculatorOptionTwoBuyPrice',
      friendlyName: 'Option 2 Buy Price',
      type: 'number',
      required: true,
    },
    {
      name: 'profitCalculatorOptionTwoAmericanOdds',
      friendlyName: 'Option 2 American Odds Profit',
      type: 'number',
      required: true,
    },
    {
      name: 'profitCalculatorOptionTwoSportsbook',
      friendlyName: 'Option 2 Sportsbook Profit',
      type: 'string',
      required: true,
    },
    {
      name: 'profitCalculatorInitialRiskAmount',
      friendlyName: 'Initial Risk Amount',
      type: 'number',
      defaultValue: 100,
      required: true,
    },
    {
      name: 'profitCalculatorSporttradeCommission',
      friendlyName: 'Sporttrade Commission',
      type: 'number',
      defaultValue: 0.98,
      required: true,
    },
  ],
})
