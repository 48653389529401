import React from 'react'
import { DataFeedCardType } from '../../types/dataFeed'
import {
  Component,
  ContentContainer,
  Subtitle,
  MarketContainer,
  Title,
  Price,
  PriceContainer,
  League,
  IconContainer,
  StyledIcon,
} from './dataFeedCard.styles'
import { AssetModel, BuilderIoReference } from '../../types/builderio-models'

interface DataFeedCardProps {
  type?: DataFeedCardType
  homeTeam?: string
  league?: string
  awayTeam?: string
  contestDate?: string
  cardIcons?: {
    cardIcon: BuilderIoReference<AssetModel, 'data'>
  }[]
  sellPrice?: string
  buyPrice?: string
  marketType?: string
}

const DataFeedCard = ({
  type,
  homeTeam,
  awayTeam,
  contestDate,
  cardIcons,
  sellPrice,
  buyPrice,
  league,
  marketType,
}: DataFeedCardProps) => {
  const getLeagueIcon = (league: string) => {
    switch (league) {
      case 'NFL':
      case 'NCAAF':
        const nflIcon = cardIcons?.find(({ cardIcon }) => cardIcon.value.data.title === 'NFL')?.cardIcon.value.data
        return <StyledIcon aria-label={nflIcon?.title} src={nflIcon?.file} />
      case 'NBA':
      case 'WNBA':
      case 'NCAAB':
        const nbaIcon = cardIcons?.find(({ cardIcon }) => cardIcon.value.data.title === 'NBA')?.cardIcon.value.data
        return <StyledIcon aria-label={nbaIcon?.title} src={nbaIcon?.file} />
      case 'MLB':
        const mlbIcon = cardIcons?.find(({ cardIcon }) => cardIcon.value.data.title === 'MLB')?.cardIcon.value.data
        return <StyledIcon aria-label={mlbIcon?.title} src={mlbIcon?.file} />
      case 'NHL':
        const nhlIcon = cardIcons?.find(({ cardIcon }) => cardIcon.value.data.title === 'NHL')?.cardIcon.value.data
        return <StyledIcon aria-label={nhlIcon?.title} src={nhlIcon?.file} />
      default:
        return <>Image Not Found</>
    }
  }

  const iconContent = (
    <IconContainer>
      {league && getLeagueIcon(league)}
      <League>{league}</League>
    </IconContainer>
  )

  const contestContent = (
    <ContentContainer>
      <Title>{`${awayTeam} at ${homeTeam}`}</Title>
      <Subtitle>{contestDate}</Subtitle>
    </ContentContainer>
  )

  const marketContent = (
    <MarketContainer>
      <ContentContainer $type={type}>
        <Title>{homeTeam}</Title>
        <Subtitle $type={type}>{marketType}</Subtitle>
      </ContentContainer>
      <PriceContainer $first={true}>
        <Subtitle>Sell</Subtitle>
        <Price>{sellPrice}</Price>
      </PriceContainer>
      <PriceContainer>
        <Subtitle>Buy</Subtitle>
        <Price $active>{buyPrice}</Price>
      </PriceContainer>
    </MarketContainer>
  )

  return (
    <Component $type={type}>
      {type === DataFeedCardType.ICON && iconContent}
      {type === DataFeedCardType.CONTEST && contestContent}
      {type === DataFeedCardType.MARKET && marketContent}
    </Component>
  )
}

export default DataFeedCard
