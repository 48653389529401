import React from 'react'
import { Builder } from '@builder.io/react'
import Title from './genericTitle'
import { themeObj } from '../Theme'

Builder.registerComponent(Title, {
  name: 'Title',
  inputs: [
    { name: 'text', type: 'text', defaultValue: 'This is a Title', required: true },
    {
      name: 'tag',
      type: 'text',
      defaultValue: 'h1',
      regex: {
        pattern: '^h[1-6]$',
        message: 'Tag must be a valid HTML heading tag (h1, h2, h3, h4, h5, h6)',
      },
      required: true,
    },
  ],
  noWrap: true,
  defaultStyles: {
    fontFamily: themeObj.fontName,
  },
})
