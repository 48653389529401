import React, { useState, useEffect } from 'react'
import betConverters from '../../helpers'
import {
  ColumnFour,
  ColumnOne,
  ColumnThree,
  ColumnTwo,
  Component,
  Grid,
  Footer,
  Icon,
  Input,
  InputWrapper,
  Label,
  Lede,
  MobileLabel,
  StyledNumber,
  NumberTwo,
  Radio,
  RadioLabel,
  RadioLabelText,
  Title,
  Text,
  GridContainer,
  Stack,
  Logo,
} from './calculator.styles'
import { AssetModel, BuilderIoReference } from '../../types/builderio-models'

interface CalculatorProps {
  profitCalculatorSporttradeLogo: BuilderIoReference<AssetModel, 'data'>
  profitCalculatorTitleOne: string
  profitCalculatorTitleTwo: string
  profitCalculatorOptionsIcon: string
  profitCalculatorOptionOneLabel: string
  profitCalculatorOptionOneBuyPrice: number
  profitCalculatorOptionOneAmericanOdds: number
  profitCalculatorOptionOneSportsbook: number
  profitCalculatorOptionTwoSportsbook: number
  profitCalculatorOptionTwoLabel: string
  profitCalculatorOptionTwoBuyPrice: number
  profitCalculatorOptionTwoAmericanOdds: number
  profitCalculatorInitialRiskAmount: number
  profitCalculatorSporttradeCommission: number
}

const Calculator = ({
  profitCalculatorSporttradeLogo,
  profitCalculatorTitleOne,
  profitCalculatorTitleTwo,
  profitCalculatorOptionsIcon,
  profitCalculatorOptionOneLabel,
  profitCalculatorOptionOneBuyPrice,
  profitCalculatorOptionOneAmericanOdds,
  profitCalculatorOptionOneSportsbook,
  profitCalculatorOptionTwoSportsbook,
  profitCalculatorOptionTwoLabel,
  profitCalculatorOptionTwoBuyPrice,
  profitCalculatorOptionTwoAmericanOdds,
  profitCalculatorInitialRiskAmount,
  profitCalculatorSporttradeCommission,
}: CalculatorProps) => {
  const [activeOption, setActiveOption] = useState('one')

  const logo = profitCalculatorSporttradeLogo?.value?.data?.file
  const optionOneBuyPrice = profitCalculatorOptionOneBuyPrice
  const optionOneAmericanOdds = profitCalculatorOptionOneAmericanOdds
  const optionOneSportsbook = profitCalculatorOptionOneSportsbook
  const optionTwoBuyPrice = profitCalculatorOptionTwoBuyPrice
  const optionTwoAmericanOdds = profitCalculatorOptionTwoAmericanOdds
  const optionTwoSportsbook = profitCalculatorOptionTwoSportsbook
  const initialRiskAmount = profitCalculatorInitialRiskAmount

  const [riskAmount, setRiskAmount] = useState(initialRiskAmount)

  const [sporttradeProfit, setSporttradeProfit] = useState(
    betConverters.calculateSporttradeProfit(initialRiskAmount, optionOneBuyPrice),
  )
  const [sportsbookBookProfit, setSportsbookProfit] = useState(
    betConverters.calculateSportsbookProfit(initialRiskAmount, optionTwoBuyPrice),
  )

  useEffect(() => {
    function set() {
      if (activeOption === 'one') {
        setSporttradeProfit(
          betConverters.calculateSporttradeProfit(riskAmount, optionOneBuyPrice, profitCalculatorSporttradeCommission),
        )

        setSportsbookProfit(betConverters.calculateSportsbookProfit(riskAmount, optionOneSportsbook))
      }

      if (activeOption === 'two') {
        setSporttradeProfit(
          betConverters.calculateSporttradeProfit(riskAmount, optionTwoBuyPrice, profitCalculatorSporttradeCommission),
        )

        setSportsbookProfit(betConverters.calculateSportsbookProfit(riskAmount, optionTwoSportsbook))
      }
    }

    set()
  }, [
    activeOption,
    riskAmount,
    optionOneAmericanOdds,
    optionTwoAmericanOdds,
    optionOneBuyPrice,
    optionTwoBuyPrice,
    sportsbookBookProfit,
    profitCalculatorSporttradeCommission,
  ])

  function handleRiskAmountChange({ target: { value } }: { target: { value: string } }) {
    const val = betConverters.round(Number(value), 0)
    const trimmed = betConverters.trim(val, 3)
    setRiskAmount(trimmed)
  }

  function handleOptionChange({ target: { value } }: { target: { value: string } }) {
    setActiveOption(value)
  }

  const renderIcon = (iconKey: string) => {
    if (iconKey === 'football') {
      return (
        <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.999 9.749h-3.89l-.476-2.375 4.275-.557a.506.506 0 0 0 .435-.584A7.747 7.747 0 0 0 6.62.08C3.268.552.555 3.267.08 6.62a7.73 7.73 0 0 0 1.596 5.938c.097.122.244.19.4.19h1.672l2.678 1.407a2.05 2.05 0 0 0 2.366-.384c1.025-1.026.471-2.295.221-2.868-.025-.06-.048-.111-.065-.154h1.34l.297 1.478c.297 1.481 1.5 2.665 2.997 2.853l.669.084c.618.077.672.084.726.085h.02c.704 0 1-.616 1-1v-3.5c0-.554-.446-1-1-1Zm-9.5 1.25a.748.748 0 0 1-.75-.75c0-.416.334-.75.75-.75s.75.334.75.75c0 .415-.334.75-.75.75Zm2.728-2.053.322.803h1.54l-.45-2.247-.612.081a1 1 0 0 0-.8 1.363Zm6.772 5.303-1.29-.163c-.81-.1-1.5-.584-1.879-1.272H15v1.435Zm-3.49-2.5h3.49v-1h-3.69l.2 1Z"
          />
        </svg>
      )
    } else if (iconKey === 'hockey') {
      return (
        <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 0C4 0 0 1 0 3s4 3 8 3 8-1 8-3-4-3-8-3ZM0 9V6c4 2 12 2 16 0v3c0 2-4 3-8 3s-8-1-8-3Z"
          />
        </svg>
      )
    } else if (iconKey === 'basketball') {
      return (
        <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m20 10-1 2-3 1V9h-1l-1 7-2-2-2 2-2-2-2 2-1-7H4v4l-3-1-1-2V6s2-6 10-6 10 6 10 6v4Zm-6-5v1H7V5h7Zm-6 7-2-1 2-2 1 2-1 1Zm2-2 1-1H9l1 1Zm2 2-1-1 1-2 2 2-2 1ZM6 9v1l1-1H6Zm0 3 1 1-1 1v-2Zm5 1-1-2-1 2 1 1 1-1Zm3-1v2l-1-1 1-1Zm0-3h-1l1 1V9Zm3-3v1H3V6h3V4h9v2h2Z"
          />
        </svg>
      )
    } else if (iconKey === 'baseball') {
      return (
        <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 13">
          <path
            fill-rule="evenodd"
            d="M15 1h1l-1 2-5 4-4 3-1-3 3-3 5-4 2 1ZM3 9l1 2-2 1v1H1l-1-2v-1h1l2-1Zm0 0 1 1h1L4 8 3 9Zm7 2a2 2 0 1 1 4 0 2 2 0 0 1-4 0Z"
            clip-rule="evenodd"
          />
        </svg>
      )
    }

    return ''
  }

  return (
    <Component>
      <GridContainer>
        <Grid>
          <ColumnOne underlined></ColumnOne>
          <ColumnTwo underlined alignLogic={false}>
            <MobileLabel>To win:</MobileLabel>
          </ColumnTwo>
          <ColumnThree underlined highlighted roundedTop>
            <Logo
              aria-label={profitCalculatorSporttradeLogo?.value?.data?.description}
              alt={profitCalculatorSporttradeLogo?.value?.data?.description}
              src={logo}
            />
            <Text highlight>Implied Probability</Text>
            <Text highlight>American Odds</Text>
          </ColumnThree>
          <ColumnFour underlined>
            <Text highlight={true}>Typical Sportbook</Text>
          </ColumnFour>
        </Grid>
        <Grid>
          <ColumnOne>
            <Lede>To win:</Lede>
          </ColumnOne>
          <ColumnTwo underlined alignLogic={false}>
            <Radio
              type="radio"
              id="option-one"
              name="options"
              value="one"
              checked={activeOption === 'one'}
              onChange={handleOptionChange}
            />
            <RadioLabel htmlFor="option-one">
              <RadioLabelText>{profitCalculatorOptionOneLabel}</RadioLabelText>
              <Icon activeOption={activeOption === 'one'}>{renderIcon(profitCalculatorOptionsIcon)}</Icon>
            </RadioLabel>
          </ColumnTwo>
          <ColumnThree underlined highlighted>
            <Stack>
              <Label activeOption={activeOption === 'one'}>Buy</Label>
              <StyledNumber activeOption={activeOption === 'one'}>{`$${optionOneBuyPrice}`}</StyledNumber>
            </Stack>
            <Stack>
              <NumberTwo activeOption={activeOption === 'one'}>{`+${optionOneAmericanOdds}`}</NumberTwo>
            </Stack>
          </ColumnThree>
          <ColumnFour underlined>
            <NumberTwo activeOption={activeOption === 'one'}>{`+${optionOneSportsbook}`}</NumberTwo>
          </ColumnFour>
        </Grid>
        <Grid>
          <ColumnOne underlined></ColumnOne>
          <ColumnTwo underlined alignLogic={false}>
            <Radio
              type="radio"
              id="option-two"
              name="options"
              value="two"
              checked={activeOption === 'two'}
              onChange={handleOptionChange}
            />
            <RadioLabel htmlFor="option-two">
              <RadioLabelText>{profitCalculatorOptionTwoLabel}</RadioLabelText>
              <Icon activeOption={activeOption === 'two'}>{renderIcon(profitCalculatorOptionsIcon)}</Icon>
            </RadioLabel>
          </ColumnTwo>
          <ColumnThree underlined highlighted>
            <Stack>
              <Label activeOption={activeOption === 'two'}>Buy</Label>
              <StyledNumber activeOption={activeOption === 'two'}>{`$${optionTwoBuyPrice}`}</StyledNumber>
            </Stack>
            <Stack>
              <NumberTwo activeOption={activeOption === 'two'}>{`${optionTwoAmericanOdds}`}</NumberTwo>
            </Stack>
          </ColumnThree>
          <ColumnFour underlined>
            <NumberTwo activeOption={activeOption === 'two'}>{`${optionTwoSportsbook}`}</NumberTwo>
          </ColumnFour>
        </Grid>
        <Grid>
          <ColumnOne>
            <Lede>Bet:</Lede>
          </ColumnOne>
          <ColumnTwo alignLogic>
            <MobileLabel>Bet:</MobileLabel>
            <InputWrapper>
              <NumberTwo activeOption={true}>$</NumberTwo>
              <Input
                type="number"
                id="risk-amount"
                name="risk-amount"
                onChange={handleRiskAmountChange}
                value={riskAmount || ''}
              />
            </InputWrapper>
          </ColumnTwo>
          <ColumnThree highlighted roundedBottom>
            <Stack>
              <Text highlight={false}>Profit on Sporttrade</Text>
              <StyledNumber activeOption={true} id="calculator-profit-result">
                ${(sporttradeProfit && betConverters.addZeros(sporttradeProfit)) || '--'}
              </StyledNumber>
            </Stack>
          </ColumnThree>
          <ColumnFour>
            <Text highlight={false}>Profit on Sportsbook</Text>
            <NumberTwo activeOption={true} id="sportsbook-profit-result">
              ${(sportsbookBookProfit && betConverters.addZeros(sportsbookBookProfit)) || '--'}
            </NumberTwo>
          </ColumnFour>
        </Grid>
      </GridContainer>
      <Footer>
        <Title>{profitCalculatorTitleOne}</Title>
        <Title>{profitCalculatorTitleTwo}</Title>
      </Footer>
    </Component>
  )
}

export default Calculator
