import React from 'react'
import { StyledTitle } from './subtitle.styles'

interface SubtitleProps {
  tag: any
  text: string | Array<string>
}

const Subtitle = ({ tag, text }: SubtitleProps) => {
  return <StyledTitle as={tag}>{text}</StyledTitle>
}

export default Subtitle
