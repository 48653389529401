import React, { ReactElement, useEffect } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider, { Settings } from 'react-slick'

import { useMarketData } from '../../context'
import { DataFeedCardType } from '../../types/dataFeed'

import DataFeedCard from '../DataFeedCard/dataFeedCard'
import { AssetModel, BuilderIoReference } from '../../types/builderio-models'
import { BuilderCustomComponentProps } from '../../types/builderio-ids'
import SkeletonLoader from './SkeletonLoader/skeletonLoader'
interface DataFeedProps extends BuilderCustomComponentProps {
  cardIcons?: {
    cardIcon: BuilderIoReference<AssetModel, 'data'>
  }[]
}

const DataFeed = ({ cardIcons = [], attributes }: DataFeedProps) => {
  const sliderSettings: Settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 5000,
    autoplaySpeed: 0,
    draggable: true,
    cssEase: 'linear',
    swipe: true,
    swipeToSlide: true,
    pauseOnHover: true,
    pauseOnFocus: true,
    waitForAnimate: true,
    variableWidth: true,
  }

  const { dataFeedCards } = useMarketData(),
    cardsArray: ReactElement[] = [],
    [hide, setHide] = React.useState(false)

  Object.entries(dataFeedCards).forEach(([symbol, cards], index) => {
    if (cards.length > 0) {
      cardsArray.push(<DataFeedCard key={index} cardIcons={cardIcons} type={DataFeedCardType.ICON} league={symbol} />)
    }
    cards.forEach((card, index) => {
      cardsArray.push(<DataFeedCard key={index} cardIcons={cardIcons} {...card} />)
    })
  })

  // add a timer of 10 seconds, if after that time the cardsArray or cardIcons are still empty, hide the component
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (cardIcons.length === 0 || cardsArray.length === 0) {
        setHide(true)
      }
    }, 10000)
    return () => clearTimeout(timeout)
  }, [cardsArray, cardIcons])

  if (hide) return null

  return (
    <div {...attributes}>
      {cardIcons.length === 0 || cardsArray.length === 0 ? (
        <SkeletonLoader />
      ) : (
        <Slider {...sliderSettings}>{cardsArray}</Slider>
      )}
    </div>
  )
}

export default DataFeed
