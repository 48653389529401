import React, { useState } from 'react'
import TitleFive from '../TitleFive/titleFive'
import { kebabCase } from 'lodash'
import { parseBuilderRichText } from '../../helpers/builderRichTextParser'
import TitleThree from '../TitleThree/titleThree'
import {
  Container,
  Component,
  Grid,
  Aside,
  TopTitle,
  Collapse,
  CollapseTrigger,
  CollapseTriggerWrapper,
  VideoIcon,
  CollapseTriggerIcon,
  ChevronIcon,
  Drawer,
  CollapseContainer,
  CollapseText,
  IframeWrapper,
  Iframe,
  Back,
  BackText,
  StyledLink,
  Body,
  Footer,
} from './accordion.styles'
import { AccordionItemModel, BuilderIoReference } from '../../types/builderio-models'

interface AccordionProps {
  title: string
  collapses: {
    accordionItem: BuilderIoReference<AccordionItemModel, 'data'>
  }[]
  linkText?: string
  linkUrl?: string
  spaced: boolean
  back: boolean
  titlePosition: string
  embedWidth: string
  layoutDefault?: boolean
}

const Accordion = ({
  title,
  collapses,
  linkText,
  linkUrl,
  spaced,
  back,
  titlePosition,
  embedWidth,
}: AccordionProps) => {
  const [activeKey, setActiveKey] = useState<number | ''>()

  const scrollTo = (event: Event) => {
    event.preventDefault()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <Component id={title ? kebabCase(title) : ''} $spaced={spaced}>
      <Container>
        <Grid>
          {title && titlePosition === 'aside' && (
            <Aside>
              <TitleThree tag={'h5'} text={title} />
            </Aside>
          )}
          <Body>
            {titlePosition === 'top' && (
              <TopTitle>
                <TitleThree tag={'h5'} text={title} />
              </TopTitle>
            )}
            {collapses?.length > 0
              ? collapses.map(({ accordionItem }, i) => {
                  const item = accordionItem?.value?.data
                  if (!item) return null
                  return (
                    <Collapse key={i}>
                      <CollapseTrigger $active={activeKey === i} onClick={() => setActiveKey(i !== activeKey ? i : '')}>
                        <CollapseTriggerWrapper>
                          <TitleFive tag="p" text={item.title} />
                          {item.showVideoIcon && (
                            <div>
                              <VideoIcon
                                $active={activeKey === i}
                                width="31"
                                height="22"
                                viewBox="0 0 31 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M5.262 0A5.25 5.25 0 0 0 0 5.262v11.276a5.251 5.251 0 0 0 5.262 5.263h19.546a5.25 5.25 0 0 0 5.262-5.262V5.261A5.25 5.25 0 0 0 24.808 0H5.262Zm6.766 6.014 8.27 4.887-8.27 4.886V6.014Z" />
                              </VideoIcon>
                            </div>
                          )}
                        </CollapseTriggerWrapper>
                        <CollapseTriggerIcon>
                          <ChevronIcon
                            width="19"
                            height="11"
                            viewBox="0 0 19 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill="#0A6DFF"
                              d="M9.183 7.336L2.237.386a1.307 1.307 0 00-1.854 0 1.319 1.319 0 000 1.859l7.87 7.875a1.31 1.31 0 001.81.038l7.924-7.908c.257-.257.383-.596.383-.93 0-.333-.126-.672-.383-.929a1.307 1.307 0 00-1.854 0l-6.95 6.945z"
                            />
                          </ChevronIcon>
                        </CollapseTriggerIcon>
                      </CollapseTrigger>
                      <Drawer $active={activeKey === i}>
                        <CollapseContainer>
                          <CollapseText>{parseBuilderRichText(item.text)}</CollapseText>
                          {item.embedUrl && (
                            <IframeWrapper $embedWidth={embedWidth}>
                              <Iframe
                                $embedWidth={embedWidth}
                                loading="lazy"
                                src={item.embedUrl}
                                title={item.title}
                                frameBorder="0"
                              />
                            </IframeWrapper>
                          )}
                        </CollapseContainer>
                      </Drawer>
                    </Collapse>
                  )
                })
              : null}
            {back && (
              <Back href="#" onClick={() => scrollTo}>
                <BackText>Back to top</BackText>
                <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.63397 0.500001C4.01887 -0.166666 4.98112 -0.166666 5.36602 0.5L8.39711 5.75C8.78201 6.41667 8.30089 7.25 7.53109 7.25L1.46891 7.25C0.699111 7.25 0.217985 6.41667 0.602885 5.75L3.63397 0.500001Z" />
                </svg>
              </Back>
            )}
          </Body>
        </Grid>
        <Footer>{linkUrl && linkText && <StyledLink to={linkUrl}>{linkText}</StyledLink>}</Footer>
      </Container>
    </Component>
  )
}

export default Accordion
