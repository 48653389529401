import { Builder } from '@builder.io/react'
import DataFeed from './dataFeed'

Builder.registerComponent(DataFeed, {
  name: 'Data Feed Ticker',
  inputs: [
    {
      name: 'cardIcons',
      type: 'list',
      subFields: [
        {
          name: 'cardIcon',
          type: 'reference',
          model: 'asset',
          required: true,
        },
      ],
    },
  ],
  noWrap: true,
})
