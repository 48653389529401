import styled from 'styled-components'

export const StyledTitle = styled.span`
  & {
    font-family: ${({ theme }) => theme.fontName};
    font-size: ${({ theme }) => theme.fontSizes.h1};
    font-weight: 800;
    line-height: 1.1;
    @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
      font-size: ${({ theme }) => theme.fontSizes.h1Medium};
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
      font-size: ${({ theme }) => theme.fontSizes.h1Large};
    }
  }
`
