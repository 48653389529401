import styled from 'styled-components'

interface ScreenStyledProps {
  $large?: boolean
}

export const Component = styled.div`
  position: relative;
`

export const Container = styled.div``

export const Foreground = styled.div`
  z-index: 100;
  position: absolute;
`

export const ScreenImage = styled.img<ScreenStyledProps>`
  width: ${(props) => (props.$large ? '24.5rem' : '17.5rem')};
  height: auto;
  margin: ${(props) => (props.$large ? '2.5rem 3.75rem' : '1.75rem 2.5rem')};
`

export const Background = styled.div``

export const InlineSvg = styled.svg<ScreenStyledProps>`
  width: ${(props) => (props.$large ? '32rem' : '22.5rem')};
  height: auto;
`
