import React from 'react'
import { Background, Component, Container, Foreground, InlineSvg, ScreenImage } from './screen.styles'

interface ScreenProps {
  large?: boolean
  artwork: { description: string; url: string }
}

const Screen = ({ large, artwork }: ScreenProps) => {
  return (
    <Component>
      <Container>
        <Foreground>
          <ScreenImage $large={large} src={artwork.url} alt={artwork.description} />
        </Foreground>
        <Background>
          <InlineSvg $large={large} viewBox="0 0 495 926" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_ddd)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M85 16c-27.614 0-50 22.386-50 50v54a2 2 0 00-2 2v29a2 2 0 002 2v34a3 3 0 00-3 3v57a3 3 0 003 3v16a3 3 0 00-3 3v57a3 3 0 003 3v499c0 27.614 22.386 50 50 50h325c27.614 0 50-22.386 50-50V308a3 3 0 003-3v-94a3 3 0 00-3-3V66c0-27.614-22.386-50-50-50H85z"
                fill="#242A31"
              />
              <path
                d="M407 21H88c-26.51 0-48 21.49-48 48v756c0 26.51 21.49 48 48 48h319c26.51 0 48-21.49 48-48V69c0-26.51-21.49-48-48-48z"
                fill="#13151B"
              />
              <path
                d="M404 24H91c-26.51 0-48 21.49-48 48v750c0 26.51 21.49 48 48 48h313c26.51 0 48-21.49 48-48V72c0-26.51-21.49-48-48-48z"
                fill="#242A31"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M144 41h-8c4.8 0 8 1.5 8 6v6c0 9.941 8.059 18 18 18h172c9.941 0 18-8.059 18-18v-6c0-4.5 3.2-6 8-6H144z"
                fill="#242A31"
              />
              <path d="M291 58a7 7 0 100-14 7 7 0 000 14z" fill="#13151B" />
              <path d="M291 55a4 4 0 100-8 4 4 0 000 8z" fill="#242A31" />
              <path d="M268 47h-41a4 4 0 000 8h41a4 4 0 000-8z" fill="#13151B" />
            </g>
            <defs>
              <filter
                id="filter0_ddd"
                x="0"
                y="0"
                width="495"
                height="926"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="4" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
                <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                <feOffset dy="16" />
                <feGaussianBlur stdDeviation="16" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend in2="effect1_dropShadow" result="effect2_dropShadow" />
                <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend in2="effect2_dropShadow" result="effect3_dropShadow" />
                <feBlend in="SourceGraphic" in2="effect3_dropShadow" result="shape" />
              </filter>
            </defs>
          </InlineSvg>
        </Background>
      </Container>
    </Component>
  )
}

export default Screen
