import React from 'react'
import TitleFive from '../TitleFive/titleFive'
import TitleFour from '../TitleFour/titleFour'
import Subtitle from '../Subtitle/subtitle'
import { Aside, Body, Component, Grid, Heading, TextBlock, Title, Text, Image, ComponentLink } from './card.styles'
import { RichTextReplacements, parseBuilderRichText } from '../../helpers/builderRichTextParser'

interface CardProps {
  slug?: string
  title: string
  heading: string
  text?: string
  image: string
  description: string
  orientation: string
  justification: string
  showText: boolean
  invertTitles?: boolean
}

const replacements: RichTextReplacements = [
  {
    nodeName: 'p',
    Component(props) {
      return (
        <TextBlock>
          <Subtitle tag="p" text={props.children} />
        </TextBlock>
      )
    },
  },
]

const Card = ({
  slug,
  title,
  heading,
  text,
  image,
  description,
  orientation,
  justification,
  showText,
  invertTitles = false,
}: CardProps) => {
  const ComponentFinal = slug ? ComponentLink : Component
  return (
    <ComponentFinal to={slug || ''}>
      <Grid $orientation={orientation}>
        <Aside $orientation={orientation} $justification={justification}>
          <Image $orientation={orientation} width="560px" height="420px" src={image} alt={description} />
        </Aside>
        <Body $orientation={orientation} $justification={justification}>
          {invertTitles ? (
            <>
              <Title>
                <TitleFour text={title} tag="h3" chevron={false} />
              </Title>
              <Heading>
                <TitleFive text={heading} tag={'p'} />
              </Heading>
            </>
          ) : (
            <>
              <Heading>
                <TitleFive text={heading} tag={'p'} />
              </Heading>
              <Title>
                <TitleFour text={title} tag="h3" chevron={false} />
              </Title>
            </>
          )}
          {showText && <Text>{parseBuilderRichText(text || '', replacements)}</Text>}
        </Body>
      </Grid>
    </ComponentFinal>
  )
}

export default Card
