export enum DisplayState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

enum TradingState {
  TRADING = 'TRADING',
  HALTED = 'HALTED',
  SETTLED = 'SETTLED',
  VOIDED = 'VOIDED',
}

export interface Contest {
  awayTeamId: string
  betGeniusMatchId: string
  contestId: string
  contestVersion: number
  eventState: string
  expectedStartTime: number
  gameStateDataSource: string
  homeTeamId: string
  instance: string
  isAggregate: boolean
  leagueId: string
  longName: string
  scheduledStartTime: number
  shortName: string
  sportRadarMatchId: string
  symbol: string
  homeBuyPrice: number
  homeSellPrice: number
  awayBuyPrice: number
  awaySellPrice: number
}

export interface League {
  leagueId: string
  longName: string
  shortName: string
  symbol: string
  sport: string
}

export interface Outcome {
  displayState: DisplayState
  followerName: string
  marketId: string
  name: string
  outcomeId: string
  settledPrice: number
  strike: string
  ticker: string
  tradingState: TradingState
  leaderParticipantId: string | null
  followerParticipantId: string | null
}

export interface Market {
  contestId: string
  longName: string
  marketId: string
  marketTypeId: string
  maxWinners: number
  minWinners: number
  period: string
  shortName: string
  symbol: string
  outcomes: Outcome[]
}

interface Participant {
  participantId: string
  longName: string
  shortName: string
  symbol: string
  primaryColor?: string
  birthDate?: string
  logoUrl?: string
}

interface PeriodType {
  periodTypeId: string
  shortName: string
  longName: string
  symbol: string
}

export enum MessageType {
  LEAGUE_SNAPSHOT = 'LeagueSnapshotEvent',
  CONTEST_SNAPSHOT = 'ContestSnapshotEvent',
  MARKET_SNAPSHOT = 'MarketSnapshotEvent',
  OUTCOME_SNAPSHOT = 'OutcomeSnapshotEvent',
  PERIODTYPE_SNAPSHOT = 'PeriodTypeSnapshotEvent',
  PARTICIPANT_SNAPSHOT = 'ParticipantSnapshotEvent',
  LEAGUE_REFRESH = 'LeagueRefreshEvent',
  CONTEST_REFRESH = 'ContestRefreshEvent',
  MARKET_REFRESH = 'MarketRefreshEvent',
  OUTCOME_REFRESH = 'OutcomeRefreshEvent',
  PERIODTYPE_REFRESH = 'PeriodTypeRefreshEvent',
  PARTICIPANT_REFRESH = 'ParticipantRefreshEvent',
  HEARTBEAT = 'Heartbeat',
}
interface MessageEventDataBase {
  message_type: MessageType
}

interface LeagueSnapshotEventData extends MessageEventDataBase {
  message_type: MessageType.LEAGUE_SNAPSHOT
  leagues: League[]
}

interface ContestSnapshotEventData extends MessageEventDataBase {
  message_type: MessageType.CONTEST_SNAPSHOT
  contests: Contest[]
}

interface MarketSnapshotEventData extends MessageEventDataBase {
  message_type: MessageType.MARKET_SNAPSHOT
  markets: Market[]
}

interface OutcomeSnapshotEventData extends MessageEventDataBase {
  message_type: MessageType.OUTCOME_SNAPSHOT
  outcomes: Outcome[]
}

interface PeriodTypeSnapshotEventData extends MessageEventDataBase {
  message_type: MessageType.PERIODTYPE_SNAPSHOT
  periodTypes: PeriodType[]
}

interface ParticipantSnapshotEventData extends MessageEventDataBase {
  message_type: MessageType.PARTICIPANT_SNAPSHOT
  participants: Participant[]
}

interface LeagueRefreshEventData extends MessageEventDataBase {
  message_type: MessageType.LEAGUE_REFRESH
  league: League
}

interface ContestRefreshEventData extends MessageEventDataBase {
  message_type: MessageType.CONTEST_REFRESH
  contest: Contest
}

interface MarketRefreshEventData extends MessageEventDataBase {
  message_type: MessageType.MARKET_REFRESH
  market: Market
}

interface OutcomeRefreshEventData extends MessageEventDataBase {
  message_type: MessageType.OUTCOME_REFRESH
  outcome: Outcome
}

interface PeriodTypeRefreshEventData extends MessageEventDataBase {
  message_type: MessageType.PERIODTYPE_REFRESH
  periodType: PeriodType
}

interface ParticipantRefreshEventData extends MessageEventDataBase {
  message_type: MessageType.PARTICIPANT_REFRESH
  participant: Participant
}

interface HeartbeatEventData extends MessageEventDataBase {
  message_type: MessageType.HEARTBEAT
}

export type MessageEventData =
  | LeagueSnapshotEventData
  | ContestSnapshotEventData
  | MarketSnapshotEventData
  | OutcomeSnapshotEventData
  | PeriodTypeSnapshotEventData
  | ParticipantSnapshotEventData
  | LeagueRefreshEventData
  | ContestRefreshEventData
  | MarketRefreshEventData
  | OutcomeRefreshEventData
  | PeriodTypeRefreshEventData
  | ParticipantRefreshEventData
  | HeartbeatEventData
