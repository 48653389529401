import React from 'react'
import { Builder } from '@builder.io/react'
import Header from './header'

Builder.registerComponent(Header, {
  name: 'Header',
  inputs: [
    { name: 'showLogoByDefault', type: 'boolean', defaultValue: true },
    { name: 'defaultNotificationBehavior', type: 'boolean', defaultValue: true },
    {
      name: 'current',
      type: 'string',
      defaultValue: 'home',
      helperText: 'The current page, used to highlight the current page in the navigation',
    },
    {
      name: 'mode',
      type: 'text',
      enum: ['default', 'white'],
      defaultValue: 'default',
    },
    {
      name: 'links',
      type: 'list',
      subFields: [
        {
          name: 'title',
          type: 'string',
          defaultValue: 'Link Title',
          required: true,
        },
        {
          name: 'id',
          type: 'string',
          defaultValue: '',
          helperText: 'The ID of the link, used to highlight the current page in the navigation',
        },
        {
          name: 'text',
          type: 'string',
          defaultValue: 'Link Description (optional)',
        },
        {
          name: 'link',
          type: 'url',
          defaultValue: '',
          helperText: 'The URL to navigate to when the link is clicked (if no sublinks are provided)',
        },
        {
          name: 'subLinks',
          type: 'list',
          subFields: [
            {
              name: 'title',
              type: 'string',
              defaultValue: 'Sub Link Title',
              required: true,
            },
            {
              name: 'text',
              type: 'string',
              defaultValue: 'Sub Link Description',
            },
            {
              name: 'id',
              type: 'string',
              defaultValue: '',
              helperText: 'Sub Link ID, used to highlight the current page in the navigation',
              required: true,
            },
            {
              name: 'link',
              type: 'url',
              defaultValue: '/',
              required: true,
            },
          ],
        },
      ],
    },
  ],
  noWrap: true,
})
