import React from 'react'
import { StyledTitle } from './titleOne.styles'

interface TitelOneProps {
  tag: any
  text: string
}

const TitleOne = ({ tag, text }: TitelOneProps) => {
  return <StyledTitle as={tag}>{text}</StyledTitle>
}

export default TitleOne
