import { Builder } from '@builder.io/react'
import Card from './card'

Builder.registerComponent(Card, {
  name: 'Card',
  inputs: [
    {
      name: 'slug',
      friendlyName: 'Card Link',
      helperText: 'Optional link for the card, leave blank for no link',
      type: 'string',
    },
    {
      name: 'orientation',
      type: 'text',
      enum: ['vertical', 'horizontal'],
      defaultValue: 'vertical',
    },
    {
      name: 'image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png'],
      required: true,
    },
    {
      name: 'description',
      friendlyName: 'Image alt text',
      type: 'string',
      defaultValue: 'Card Image',
      required: true,
    },
    {
      name: 'invertTitles',
      friendlyName: 'Invert Titles',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'heading',
      friendlyName: 'Card Sub Heading',
      type: 'string',
      defaultValue: 'Card Sub Heading',
      required: true,
    },
    {
      name: 'title',
      friendlyName: 'Card Title',
      type: 'string',
      defaultValue: 'Card Title',
      required: true,
    },
    {
      name: 'text',
      friendlyName: 'Card Long Text',
      type: 'html',
      defaultValue: 'Card Text',
      required: true,
    },
    {
      name: 'showText',
      friendlyName: 'Show Text',
      type: 'boolean',
      defaultValue: false,
    },
  ],
})
