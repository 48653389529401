import { Builder } from '@builder.io/react'
import Accordion from './accordion'
import { AccordionItemID } from '../../types/builderio-ids'

Builder.registerComponent(Accordion, {
  name: 'Accordion',
  inputs: [
    { name: 'title', type: 'string', defaultValue: 'Accordion Title', required: true },
    {
      name: 'titlePosition',
      type: 'string',
      enum: ['aside', 'top', 'none'],
      defaultValue: 'none',
      helperText: 'Position of the subtitle in the accordion',
    },
    { name: 'spaced', type: 'boolean', defaultValue: false, helperText: 'Add space at the bottom of accordion' },
    {
      name: 'back',
      type: 'boolean',
      defaultValue: false,
      helperText: 'Show a back to top button at the bottom of the accordion',
    },
    {
      name: 'collapses',
      type: 'list',
      subFields: [
        {
          name: 'accordionItem',
          type: 'reference',
          model: 'accordion-item' satisfies AccordionItemID,
          options: {
            enrich: true,
          },
          required: true,
        },
      ],
      required: true,
    },
    {
      name: 'linkText',
      type: 'string',
      defaultValue: '',
      helperText: 'Text for the link button at the bottom of the accordion',
    },
    {
      name: 'linkUrl',
      type: 'string',
      defaultValue: '',
      helperText: 'URL for the link button at the bottom of the accordion',
    },
    {
      name: 'embedWidth',
      type: 'string',
      enum: ['full', 'default'],
      defaultValue: 'default',
      helperText: 'Iframe width of the accordion',
    },
  ],
})
