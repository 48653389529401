/**
 * @param {number} percent - An absolute number to convert to decimal
 */
export function percentToDecimal(percent: string) {
  return parseFloat(percent) / 100
}

/**
 * @param {number} value - A number
 */
export function removeLastNumber(value: number) {
  return Math.floor(value / 10)
}

/**
 * @param {number} value - A number
 */
export function addZeros(value: number) {
  return value.toFixed(2)
}

/**
 * @param {number} value
 * @param {number} decimals
 */
export function round(value: number, decimals: number) {
  return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals)
}

/**
 * @param {number} value
 * @param {number} leng
 */
export function trim(value: number, leng: number) {
  return value.toString().length > leng ? removeLastNumber(value) : value
}

/**
 * @param {number} impliedProbability - A number that's between 1 and 100
 */
export function impliedProbabilityToAmericanOdds(impliedProbability: number) {
  const impliedProbabilityAsDecimel = Math.abs(percentToDecimal(impliedProbability.toString()))

  if (impliedProbability > 50) {
    return round(-(impliedProbabilityAsDecimel / (1 - impliedProbabilityAsDecimel)) * 100, 0)
  } else {
    return round(((1 - impliedProbabilityAsDecimel) / impliedProbabilityAsDecimel) * 100, 0)
  }
}

/**
 * @param {number} americanOdds- A number > 100 or < -100
 */
export function americanOddsToImpliedProbability(americanOdds: number) {
  const americanOddsAbsolute = Math.abs(americanOdds)
  if (americanOdds >= 99) {
    return round((100 / (americanOddsAbsolute + 100)) * 100, 2)
  } else if (americanOdds <= -100) {
    return round((americanOddsAbsolute / (americanOddsAbsolute + 100)) * 100, 2)
  }
}

/**
 * @param {number} riskAmount
 * @param {number} buyPrice
 */
export function calculateSporttradeProfit(riskAmount: number, buyPrice: number, commission?: number) {
  return commission
    ? round((riskAmount / buyPrice) * (100 - buyPrice) * commission, 2)
    : round((riskAmount / buyPrice) * (100 - buyPrice), 2)
}

/**
 * @param {number} riskAmount
 * @param {number} americanOdds- A number > 100 or < -100
 */
export function calculateSportsbookProfit(riskAmount: number, americanOdds: number) {
  if (americanOdds > 99) {
    return round((americanOdds / 100) * riskAmount, 2)
  } else if (americanOdds < -100) {
    return round(((Math.abs(americanOdds) + 100) / Math.abs(americanOdds) - 1) * riskAmount, 2)
  }
}
