import { Builder } from '@builder.io/react'
import Screen from './screen'

Builder.registerComponent(Screen, {
  name: 'Phone Screen',
  inputs: [
    {
      name: 'artwork',
      friendlyName: 'Image of the screen',
      type: 'object',
      defaultValue: {
        url: 'https://cdn.builder.io/api/v1/image/assets%2Ff127073fccdc44a590bf4362d8bb100b%2F6b7798096f254fc5a741f404a071a990',
        description: 'Image of the screen',
      },
      subFields: [
        {
          name: 'url',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
        },
        {
          name: 'description',
          type: 'string',
        },
      ],
    },
    {
      name: 'large',
      type: 'boolean',
      defaultValue: false,
      friendlyName: 'Large screen',
    },
  ],
})
