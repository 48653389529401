import styled from 'styled-components'

interface NotificationStyledProps {
  $alertColor: string
  $successColor: string
  $widthMobile?: string
  $notificationType?: string
  $viewport?: string
}

export const Component = styled.a<NotificationStyledProps>`
  width: ${(props) => props.$widthMobile};
  transition: 0.5s ease background;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.85rem;
  padding-bottom: 1.85rem;
  color: ${({ theme }) => theme.primaryWhite};
  font-family: ${({ theme }) => theme.fontName};
  font-weight: 700;
  font-size: 1.6rem;
  text-align: center;
  text-decoration: none;
  background: ${(props) => (props.$notificationType === 'alert' ? props.$alertColor : props.$successColor)};
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: ${(props) => (props.$viewport === 'medium' || props.$viewport === 'none' ? 'none' : 'flex')};
    width: 100%;
  }
  &:hover,
  &:focus {
    background: ${(props) =>
      props.$notificationType === 'alert' ? ({ theme }) => theme.primaryBlack : ({ theme }) => theme.secondaryGreen};
  }
`

export const Text = styled.span`
  font-family: ${({ theme }) => theme.fontName};
  font-weight: 700;
  font-size: 1.3rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-weight: 700;
    font-size: 1.6rem;
  }
`

export const Arrow = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: inline-block;
    padding-left: 0.5rem;
  }
`

export const Chevron = styled.div`
  display: inline-block;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }
`

export const TextFull = styled.span`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: inline-block;
  }
`

export const TextTruncated = styled.span`
  display: inline-block;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }
`
