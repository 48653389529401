import styled from 'styled-components'
import { DataFeedCardType } from '../../types/dataFeed'

interface DataFeedCardStyledProps {
  $type?: DataFeedCardType
  $active?: boolean
  $first?: boolean
}

export const Component = styled.div<DataFeedCardStyledProps>`
  margin: 40px 25px;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  background-color: ${(props) => {
    switch (props.$type) {
      case DataFeedCardType.ICON:
        return '#1B1E28'
      case DataFeedCardType.CONTEST:
        return '#EFF1ED'
      case DataFeedCardType.MARKET:
        return '#FFFFFF'
    }
  }};
  width: ${(props) => {
    switch (props.$type) {
      case DataFeedCardType.ICON:
        return '115px; height: 115px;'
      case DataFeedCardType.CONTEST:
        return '315px'
      case DataFeedCardType.MARKET:
        return '475px'
    }
  }};
`

export const ContentContainer = styled.div<DataFeedCardStyledProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 115px;
  padding: 0 20px;
  ${(props) =>
    props.$type === DataFeedCardType.MARKET ? 'background-color: #DDE2E5; border-radius: 8px 0 0 8px;' : ''}
`

export const MarketContainer = styled.div`
  display: flex;
`

export const PriceContainer = styled(ContentContainer)`
  width: 35%;
  ${(props) => (props.$first ? 'border-right: 1px solid #EFF1ED' : '')};
`

export const IconContainer = styled(ContentContainer)`
  height: 100%;
  padding: 0;
`

export const Title = styled.div<DataFeedCardStyledProps>`
  font-family: Poppins, san-serif;
  font-size: 20px;
  font-weight: 600;
`

export const Subtitle = styled(Title)`
  font-size: 12px;
  color: ${(props) => (props.$type === DataFeedCardType.MARKET ? '#44484C' : '#60605f')};
  font-weight: ${(props) => (props.$type === DataFeedCardType.MARKET ? '600' : '500')};
`

export const Price = styled(Title)`
  font-size: 34px;
  color: ${(props) => (props.$active ? '#00875B' : '#868F96')};
`

export const League = styled(Title)`
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
`

export const StyledIcon = styled.img`
  align-self: center;
  height: 35px;
  width: 35px;
  margin-bottom: 10px;
`
