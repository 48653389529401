import React, { Fragment, ReactElement } from 'react'
import parse, { DOMNode, domToReact, HTMLReactParserOptions, Element } from 'html-react-parser'

const options: HTMLReactParserOptions = {
  htmlparser2: { lowerCaseTags: false },
}

export type RichTextReplacements = { nodeName: Lowercase<string>; Component: JSX.ElementType }[]

/**
 * Wrapper function that parses the rich text with html-react-parser using the replacements if provided.
 *
 * `By default, the function will replace list items with a list item wrapped in a paragraph tag to match
 * the default behavior of rich text fields in Contentful (can be opt-out passing a custom replacement for li items).`
 *
 * `Also replaces embedded entries with a span element with data attributes for the content type and builder ID.`
 *
 * @param html - The HTML string to parse.
 * @param replacements - The replacements to pass to html-react-parser.
 * @returns The parsed HTML.
 */
export const parseBuilderRichText = (html: string, replacements: RichTextReplacements = []) =>
  parse(html, {
    ...options,
    replace: (domNode: DOMNode) => {
      // The embedded entry pattern is a string that starts with 'embedded-entry-block' inside a p tag
      if (domNode.type === 'tag' && domNode.name === 'p') {
        const firstChild = domNode.children?.[0] || null
        // If the node is a paragraph with a single text node child
        if (firstChild && firstChild?.type === 'text') {
          // Check if the text data contains the embedded entry pattern
          if (firstChild.data.includes('embedded-entry-block')) {
            // Split the text data into parts that match the embedded entry pattern
            // (this is for the possible case where there are multiple embedded entries in the same paragraph)
            const entries = firstChild.data.split(/(?=embedded-entry-block)/g).filter(Boolean)
            const elements = entries.map((entryText) => {
              // Replace newlines and tabs with spaces, then trim the string
              const trimmedText = entryText.replace(/[\n\t]/g, ' ').trim()
              // Match the content type and builder ID from the trimmed text
              const match = trimmedText.match(/embedded-entry-block.*?content_type:\s*([\w]+).*?builder_id:\s*([\w]+)/)
              if (match) {
                const [, contentType, builderId] = match
                return (
                  <span key={builderId} data-builder-model={contentType} data-builder-id={builderId}>
                    ENTRY
                  </span>
                )
              }
              // If no match, return nothing (the node will be interpreted as a text node)
              return
            })
            // return a fragment with only the elements that are not falsy
            // this is done to avoid issues with the parsers since it doesn't handle arrays of elements
            return <Fragment>{elements.filter(Boolean)}</Fragment>
          }
        }
      }

      if (domNode.type !== 'tag') {
        // If the node is not a tag and doesn't contain embedded entries, return nothing
        return
      }

      const replacement = replacements.find(({ nodeName }) => nodeName === domNode.name)
      if (replacement) {
        const { Component } = replacement
        const attribsWithNoStyle = { ...domNode.attribs, style: {} }
        return <Component {...attribsWithNoStyle}>{DomToReact(domNode.children as DomNode[], replacements)}</Component>
      }
    },
  })

// HELPERS
type BufferType = { deepLevel: number; item: JSX.Element }
let listBuffer: Array<BufferType> = []

const flushListBuffer = (ListComponent: JSX.ElementType) => {
  if (listBuffer.length === 0) return null

  const buildNestedLists = (items: Array<BufferType>, currentLevel: number = 0): JSX.Element[] => {
    const nestedItems: JSX.Element[] = []
    let i = 0

    while (i < items.length) {
      const { deepLevel, item } = items[i]

      if (deepLevel === currentLevel) {
        nestedItems.push(React.cloneElement(item, { key: `${currentLevel}-${i}` }))
        i++
      } else if (deepLevel > currentLevel) {
        const nestedLevelItems: Array<BufferType> = []

        while (i < items.length && items[i].deepLevel > currentLevel) {
          nestedLevelItems.push(items[i])
          i++
        }

        nestedItems.push(
          deepLevel === 0 ? (
            <ListComponent key={`${currentLevel}-${nestedItems.length}`}>
              {buildNestedLists(nestedLevelItems, currentLevel + 1)}
            </ListComponent>
          ) : (
            <div key={`${currentLevel}-${nestedItems.length}`}>
              <ListComponent>{buildNestedLists(nestedLevelItems, currentLevel + 1)}</ListComponent>
            </div>
          ),
        )
      } else {
        break
      }
    }

    return nestedItems
  }

  const nestedListContent = buildNestedLists(listBuffer)

  listBuffer = []
  return (
    <div>
      <ListComponent>{nestedListContent}</ListComponent>
    </div>
  )
}

export const DomToReact = (children: DomNode[], replacements: RichTextReplacements = []) =>
  domToReact(children as DomNode[], {
    ...options,
    replace: (domNode: DOMNode) => {
      if (domNode.type !== 'tag') {
        // If the node is not a tag and doesn't contain embedded entries, return nothing
        return
      }

      const replacement = replacements.find(({ nodeName }) => nodeName === domNode.name)
      if (replacement) {
        const { Component } = replacement
        const attribsWithNoStyle = { ...domNode.attribs, style: {} }
        return <Component {...attribsWithNoStyle}>{domToReact(domNode.children as DomNode[], options)}</Component>
      }

      // If the node is a list item and the options object does not change the replace logic for li items,
      // wrap the children in a paragraph tag (this is the default behavior for list items in rich text fields in Contentful)
      if (domNode.type === 'tag' && domNode.name === 'li') {
        const style = domNode.attribs?.style || ''
        const isNested = style.includes('text-indent')
        const indentLevel = parseFloat(style.match(/text-indent:\s*([\d.]+)em/)?.[1] || '0')
        const CustomP = replacements.find(({ nodeName }) => nodeName === 'p')?.Component
        const LiComponent = CustomP ? (
          <li>
            <CustomP>{DomToReact(domNode.children as DomNode[], replacements)}</CustomP>
          </li>
        ) : (
          <li>
            <p>{DomToReact(domNode.children as DomNode[], replacements)}</p>
          </li>
        )
        const parent = domNode.parent as Element
        const listType = parent?.name === 'ol' ? 'ol' : 'ul'

        if (isNested) {
          // add the list item to the buffer
          listBuffer.push({
            deepLevel: indentLevel,
            item: LiComponent,
          })
          // return a fragment to avoid showing the list item
          return <></>
        }

        if (listBuffer.length > 0) {
          const ListComponent = replacements.find(({ nodeName }) => nodeName === listType)?.Component || listType
          const listToReturn = flushListBuffer(ListComponent)
          return (
            <>
              {listToReturn}
              {LiComponent}
            </>
          )
        }
        return LiComponent
      }
    },
  })

export type DomNode = DOMNode

/**
 * Get Builder io's unique identifier for a model converting its model name
 * @param modelName - The model name to convert
 * @returns The unique identifier for the model
 * @example
 * ```tsx
 * const uniqueIdentifier = getUniqueIdentifiersFromModelName('blogPostCallout')
 * // uniqueIdentifier === 'blog-post-callout'
 * ```
 */
export const getModelIdentifierFromModelName = (modelName: string) => modelName.replace(/([A-Z])/g, '-$1').toLowerCase()

/**
 * Check if a React element is an embedded entry.
 * An embedded entry is a span element with data attributes for the content type and builder ID.
 *
 * @param node - The React element to check.
 * @returns Whether the element is an embedded entry.
 *
 * @example
 * ```tsx
 * const isEmbedded = isEmbeddedEntry(<span data-builder-id="123" data-builder-model="blogPostCallout">ENTRY</span>)
 * // isEmbedded === true
 * ```
 **/
export const isEmbeddedEntry = (node: ReactElement) => {
  return (
    Array.isArray(node.props?.children) &&
    node.props?.children?.some(
      (child: ReactElement) =>
        child.type === 'span' && child.props?.['data-builder-id'] && child.props?.['data-builder-model'],
    )
  )
}
