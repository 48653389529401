import React from 'react'
import styled from 'styled-components'
import { BuilderCustomComponentProps } from '../../types/builderio-ids'

const StyledTitle = styled.span`
  font-family: ${({ theme }) => theme.fontName};
`
interface GenericTitleProps extends BuilderCustomComponentProps {
  tag: string
  text: string
}

/**
 * Generic Title component for any type of title with custom tag in Builder.io
 * This is to be able to use custom tags for titles in Builder.io and also style them
 **/
const GenericTitle = ({ tag = 'h1', text, attributes }: GenericTitleProps) => {
  return (
    <StyledTitle {...attributes} as={tag}>
      {text}
    </StyledTitle>
  )
}

export default GenericTitle
