import React from 'react'
import { StyledTitle } from './calloutText.styles'

interface CalloutTextProps {
  tag: any
  text: string
}

const CalloutText = ({ tag, text }: CalloutTextProps) => {
  return <StyledTitle as={tag}>{text}</StyledTitle>
}

export default CalloutText
