import React from 'react'
import { GatsbyBrowser } from 'gatsby'

import Theme from './src/components/Theme/theme'
import { MarketDataProvider } from './src/context/marketData'
import './src/global.css'
import './src/components/builder-components'

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <Theme>
    <MarketDataProvider>{element}</MarketDataProvider>
  </Theme>
)
