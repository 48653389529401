import styled from 'styled-components'
import { Link } from 'gatsby'

interface CardStyledProp {
  $orientation?: string
  $justification?: string
}

export const Title = styled.div`
  transition: 0.5s ease color;
  display: inline-flex;
  align-items: center;
  margin-bottom: 1.7rem;
  color: ${({ theme }) => theme.primaryBlack};
`

export const ComponentLink = styled(Link)<CardStyledProp>`
  display: block;
  padding-bottom: 6.7rem;
  text-decoration: none;
  &:hover ${Title}, &:focus ${Title} {
    color: ${({ theme }) => theme.primaryGreen};
  }
`

export const Component = styled.div<any>`
  display: block;
  padding-bottom: 6.7rem;
  text-decoration: none;
`

export const Grid = styled.div<CardStyledProp>`
  display: ${(props) => (props.$orientation === 'horizontal' ? 'flex' : 'block')};
  flex-direction: ${(props) => (props.$orientation === 'horizontal' ? 'column' : 'initial')};
  padding-bottom: ${(props) => (props.$orientation === 'horizontal' ? '6.4rem' : 'none')};
  border-bottom: ${(props) => (props.$orientation === 'horizontal' ? '1px solid #EFF1ED' : 'none')};
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: ${(props) => (props.$orientation === 'horizontal' ? 'row' : 'initial')};
  }
`

export const Body = styled.div<CardStyledProp>`
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    order: ${(props) => (props.$justification === 'right' ? '2' : '1')};
    width: ${(props) => (props.$orientation === 'horizontal' ? `70%` : '100%')};
    margin-right: ${(props) =>
      props.$orientation === 'horizontal' && props.$justification === 'left' ? '12.2rem' : '0'};
  }
`

export const Aside = styled.div<CardStyledProp>`
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    order: ${(props) => (props.$justification === 'right' ? '1' : '2')};
    width: ${(props) => (props.$orientation === 'horizontal' ? `30%` : '100%')};
    margin-right: ${(props) =>
      props.$orientation === 'horizontal' && props.$justification === 'right' ? '12.2rem' : '0'};
  }
`

export const Heading = styled.div`
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.secondaryBlack};
`

export const Text = styled.div`
  color: ${({ theme }) => theme.secondaryBlack};
`

export const TextBlock = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }
`

export const Image = styled.img<CardStyledProp>`
  max-width: 100%;
  height: auto;
  margin-bottom: 3rem;
  border-radius: 10px;
  box-shadow:
    0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: ${(props) => (props.$orientation === 'vertical' ? '6rem' : '0')};
  }
`
