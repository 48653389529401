import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 3%;
`

export const Video = styled.video`
  position: relative;
  width: 225px;
  top: 65px;
  right: 15px;

  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    top: 235px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    top: 205px;
    right: 25px;
    width: 340px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    width: 377px;
    right: 27px;
    top: 210px;
  }
`

export const StaticImage = styled.img`
  position: relative;
  width: 225px;
  top: 291px;
  right: 15px;
  background: #242a31;
`

export const Svg = styled.svg`
  position: absolute;
  top: -125px;
  right: 0;
  z-index: 100;
  width: 253px;

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    top: 275px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    top: 225px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 390px;
    top: 185px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    width: 431px;
  }
`
