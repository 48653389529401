import styled from 'styled-components'

export const StyledTitle = styled.span`
  font-family: ${({ theme }) => theme.fontName};
  font-size: ${({ theme }) => theme.fontSizes.h4};
  font-weight: 700;
  line-height: 1.4;
  svg {
    margin-left: 2rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: ${({ theme }) => theme.fontSizes.h4Medium};
  }
`
