import { LookupObject } from '../types/parsing'

export const arrayToObject = <T extends object, K extends Extract<keyof T, string>>(arrayOfObjects: T[], key: K) =>
  arrayOfObjects.reduce<LookupObject<T>>((result, current) => {
    if (typeof current[key] === 'string') {
      const value = current[key] as string
      result[value] = current
    }
    return result
  }, {})
