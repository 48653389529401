import React from 'react'
import {
  Button,
  Control,
  FormContainer,
  FormText,
  FormTitle,
  Input,
  Label,
  Select,
  Textarea,
  Option,
} from './from.styles'
import { BuilderCustomComponentProps } from '../../types/builderio-ids'

interface FormProps extends BuilderCustomComponentProps {
  title: string
  text: string
}

const Form = ({ title, text, attributes }: FormProps) => {
  return (
    <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST" {...attributes}>
      <FormContainer>
        <FormTitle>{title}</FormTitle>
        <FormText>{text}</FormText>
        <input type="hidden" name="oid" value="00D5Y000001dYap" />
        <input type="hidden" name="retURL" value="https://getsporttrade.com/confirmation/" />
        <input id="lead_source" name="lead_source" type="hidden" value="Contest" />
        <Control>
          <Label htmlFor="00N5Y00000TSS9D">Birthdate (mm-dd-yyyy)</Label>
          <Input
            id="00N5Y00000TSS9D"
            name="00N5Y00000TSS9D"
            size={12}
            type="text"
            pattern="(0[1-9]|1[012])/(0[1-9]|1[0-9]|2[0-9]|3[01])/[0-9]{4}"
            aria-required="true"
            placeholder="Birthdate (mm/dd/yyyy)"
            required
          />
        </Control>
        <Control>
          <Label htmlFor="state">State/Province</Label>
          <Select id="state" name="state" defaultValue="state">
            <Option value="state" disabled>
              State
            </Option>
            <Option value="Alabama">Alabama</Option>
            <Option value="Alaska">Alaska</Option>
            <Option value="Arizona">Arizona</Option>
            <Option value="Arkansas">Arkansas</Option>
            <Option value="California">California</Option>
            <Option value="Colorado">Colorado</Option>
            <Option value="Connecticut">Connecticut</Option>
            <Option value="Delaware">Delaware</Option>
            <Option value="District of Columbia">District of Columbia</Option>
            <Option value="Florida">Florida</Option>
            <Option value="Georgia">Georgia</Option>
            <Option value="Hawaii">Hawaii</Option>
            <Option value="Idaho">Idaho</Option>
            <Option value="Illinois">Illinois</Option>
            <Option value="Indiana">Indiana</Option>
            <Option value="Iowa">Iowa</Option>
            <Option value="Kansas">Kansas</Option>
            <Option value="Kentucky">Kentucky</Option>
            <Option value="Louisiana">Louisiana</Option>
            <Option value="Maine">Maine</Option>
            <Option value="Maryland">Maryland</Option>
            <Option value="Massachusetts">Massachusetts</Option>
            <Option value="Michigan">Michigan</Option>
            <Option value="Minnesota">Minnesota</Option>
            <Option value="Mississippi">Mississippi</Option>
            <Option value="Missouri">Missouri</Option>
            <Option value="Montana">Montana</Option>
            <Option value="Nebraska">Nebraska</Option>
            <Option value="Nevada">Nevada</Option>
            <Option value="New Hampshire">New Hampshire</Option>
            <Option value="New Jersey">New Jersey</Option>
            <Option value="New Mexico">New Mexico</Option>
            <Option value="New York">New York</Option>
            <Option value="North Carolina">North Carolina</Option>
            <Option value="North Dakota">North Dakota</Option>
            <Option value="Ohio">Ohio</Option>
            <Option value="Oklahoma">Oklahoma</Option>
            <Option value="Oregon">Oregon</Option>
            <Option value="Pennsylvania">Pennsylvania</Option>
            <Option value="Rhode Island">Rhode Island</Option>
            <Option value="South Carolina">South Carolina</Option>
            <Option value="South Dakota">South Dakota</Option>
            <Option value="Tennessee">Tennessee</Option>
            <Option value="Texas">Texas</Option>
            <Option value="Utah">Utah</Option>
            <Option value="Vermont">Vermont</Option>
            <Option value="Virginia">Virginia</Option>
            <Option value="Washington">Washington</Option>
            <Option value="West Virginia">West Virginia</Option>
            <Option value="Wisconsin">Wisconsin</Option>
            <Option value="Wyoming">Wyoming</Option>
          </Select>
        </Control>
        <Control>
          <Label htmlFor="email">Email</Label>
          <Input
            id="email"
            maxLength={80}
            name="email"
            size={20}
            type="text"
            aria-required="true"
            placeholder="Email Address"
            required
          />
        </Control>
        <Control>
          <Label htmlFor="00N5Y00000QdN5p">Golfer Selection</Label>
          <Input
            id="00N5Y00000QdN5p"
            maxLength={50}
            name="00N5Y00000QdN5p"
            size={20}
            type="text"
            aria-required="true"
            placeholder="Golfer Selection"
            required
          />
        </Control>
        <Button type="submit" name="submit">
          Submit
        </Button>
      </FormContainer>
    </form>
  )
}

export default Form
