import React from 'react'
import { SkeletonBoxLarge, SkeletonBoxMedium, SkeletonBoxSmall, SkeletonContainer } from './skeletonLoader.styles'

const SkeletonLoader = () => {
  return (
    <SkeletonContainer>
      <SkeletonBoxSmall />
      <SkeletonBoxMedium />
      <SkeletonBoxLarge />
      <SkeletonBoxSmall />
      <SkeletonBoxMedium />
    </SkeletonContainer>
  )
}

export default SkeletonLoader
