import { format, formatInTimeZone, toDate } from 'date-fns-tz'

const timeZone = 'US/Eastern'

export const formatUTCSecondsToDate = (utcSeconds: string) =>
  `${formatInTimeZone(+utcSeconds * 1000, timeZone, 'EEE, MMM dd, h:mm a', { addSuffix: true })} EST`

export const formatUTCSecondsToUIDate = (utcSeconds: string | undefined) => {
  if (!utcSeconds) return ''

  return formatInTimeZone(+utcSeconds * 1000, timeZone, 'yyyy-MM-dd')
}

export const convertEasternTimeToUtc = (ddmmyyyTimeTz: string) => {
  const dateTimeParts = ddmmyyyTimeTz.split(' '),
    dateParts = dateTimeParts[0].split('/'),
    day = dateParts[0],
    month = dateParts[1],
    year = dateParts[2]

  return toDate(`${year}-${month}-${day}T${dateTimeParts[1]}`, { timeZone: 'US/Eastern' })
}

export const formatBuilderDate = (publishedAt: string | number) =>
  format(new Date(publishedAt), 'MMMM do, yyyy', { timeZone })
