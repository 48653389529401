import React from 'react'
import { StyledTitle } from './titleFour.styles'

interface TitleFourProps {
  tag: any
  text: string
  chevron?: boolean
}

const TitleFour = ({ tag, text, chevron }: TitleFourProps) => {
  return (
    <StyledTitle as={tag}>
      {text}
      {chevron && (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 2l7 7-7 7" stroke="#00875B" strokeWidth="3" />
        </svg>
      )}
    </StyledTitle>
  )
}

export default TitleFour
