import React, { useEffect, useState } from 'react'
import { debounce } from 'lodash'
import Subtitle from '../Subtitle/subtitle'
import TitleOne from '../TitleOne/titleOne'
import BadgeApple from '../BadgeApple/badgeApple'
import BadgeAndroid from '../BadgeAndroid/badgeAndroid'
import ScreenVideo from '../ScreenVideo/screenVideo'
import TextForm from '../TextForm/textForm'
import {
  Artwork,
  ArtworkContainer,
  BadgeColumn,
  BadgeFirst,
  BadgeGrid,
  Badges,
  Body,
  Component,
  Container,
  Icon,
  IconArtwork,
  Logo,
  Notice,
  NoticeSubTitle,
  NoticeText,
  NoticeTitle,
  NoticeWrapper,
  SubText,
  Title,
} from './hero.styles'
import { BuilderIoReference, GlobalModel, LogoModel } from '../../types/builderio-models'
import { RichTextReplacements, parseBuilderRichText } from '../../helpers/builderRichTextParser'

const replacements: RichTextReplacements = [
  {
    nodeName: 'p',
    Component(props) {
      return <Subtitle tag="p" text={props.children} />
    },
  },
]

interface HeroProps {
  globalData: BuilderIoReference<GlobalModel, 'data'>
  homePageData: {
    heroNoticeArtwork: string
    heroNoticeTitle: string
    heroNoticeText: string
    heroText: string
    heroArtwork: string
    heroTitleOne: string
    heroTitleTwo: string
    sporttradeLogo: BuilderIoReference<LogoModel, 'data'>
  }
}

const Hero = ({ globalData, homePageData }: HeroProps) => {
  const [scroll, setScroll] = useState(true)

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScroll(false)
    } else {
      setScroll(true)
    }
  }

  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, 25)
    window.addEventListener('scroll', debouncedHandleScroll)
    return () => window.removeEventListener('scroll', debouncedHandleScroll)
  }, [])

  if (!globalData || !homePageData) {
    return null
  }

  const { appleAppDownloadLink, androidAppDownloadLink, textALinkTerms } = globalData.value.data

  const {
    heroNoticeArtwork,
    heroText,
    heroArtwork,
    heroNoticeText,
    heroNoticeTitle,
    heroTitleOne,
    heroTitleTwo,
    sporttradeLogo,
  } = homePageData

  return (
    <Component>
      <Container>
        <div>
          <Body>
            <Logo>{scroll && <img width="229" src={sporttradeLogo.value.data.image.file}></img>}</Logo>
            <Title>
              <TitleOne tag="span" text={heroTitleOne} />
            </Title>
            {heroTitleTwo && (
              <Title>
                <TitleOne tag="span" text={heroTitleTwo} />
              </Title>
            )}
            <SubText>{parseBuilderRichText(heroText, replacements)}</SubText>
          </Body>
          <NoticeWrapper>
            <Notice>
              <Icon>
                <IconArtwork
                  height="80px"
                  width="80px"
                  src={heroNoticeArtwork}
                  alt={`Artwork for ${heroNoticeTitle}`}
                />
              </Icon>
              <NoticeTitle>{heroNoticeTitle}</NoticeTitle>
              <NoticeText>{parseBuilderRichText(heroNoticeText)}</NoticeText>
              <TextForm drawerText={textALinkTerms} />
            </Notice>
            <Badges>
              <NoticeSubTitle>Available on:</NoticeSubTitle>
              <BadgeGrid>
                <BadgeColumn>
                  <BadgeFirst>
                    <BadgeApple target={appleAppDownloadLink} id="homepage-badge-link" inverse={true} />
                  </BadgeFirst>
                </BadgeColumn>
                <BadgeColumn>
                  <BadgeAndroid target={androidAppDownloadLink} id="homepage-badge-link" inverse={true} />
                </BadgeColumn>
              </BadgeGrid>
            </Badges>
          </NoticeWrapper>
        </div>
        <ArtworkContainer>
          <Artwork>
            <ScreenVideo
              artwork={{
                url: heroArtwork,
                description: 'Hero artwork for Sporttrade',
              }}
            />
          </Artwork>
        </ArtworkContainer>
      </Container>
    </Component>
  )
}
export default Hero
